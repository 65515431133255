@font-face {
  font-family: "SofiaProLight";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("SofiaProLight"),
    url("./assets/fonts/SofiaProLight.woff2") format("woff2");
}
@font-face {
  font-family: "SofiaProRegular";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("SofiaProRegular"),
    url("./assets/fonts/SofiaProRegular.woff2") format("woff2");
}

.noti-mbl {
  width: 60px;
  text-align: center;
  color: #fff;
}

.profile-dropdown {
  box-shadow: rgb(33 36 50 / 13%) 0px 10px 25px 0px;
  top: 5px !important;
}

.fa-bell {
  color: #fff;
}
.noti-count-mbl {
  background: #dd5a5f;
  height: 15px;
  width: 15px;
  border-radius: 101px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  right: 14px;
  top: -3px;
}
.bell-mbl {
  font-size: 20px;
}

.redeem-box {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 0px;
}

.notification-time {
  font-size: 12px !important;
}

.head-line-notify {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modal-title {
  font-family: "SofiaProLight";
}
.tdetails-table {
  font-family: "SofiaProLight";
}
.tdetails-table {
  width: 100%;
}
.textarea-redeem {
  text-decoration: none;
  border: none;
  height: 190px;
  width: 95%;
  background-color: #fff;
}
.textarea-redeemwrap {
  width: 100%;
  text-align: center;
  border: 1px solid #ced8e5;
  padding: 33px 0px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.copy-span {
  padding-left: 5px;
}
.copy {
  position: absolute !important;
  right: 10px;
  top: 10px;
}
.cs-box {
  background: #fff;
  padding: 40px;
}

.tdetails-table tr td {
  padding-bottom: 10px;
}
.activation-bar {
  text-align: center !important;
  background-color: #f2dede !important;
  color: #a94442 !important;
  padding: 6px !important;
}
.activate-link {
  border-bottom: 1px solid;
}
.activation-bar-mbl {
  font-size: 12px;
}
.tdetails-table tr th {
  text-transform: uppercase;
  color: #b6b6b6;
  font-weight: 100 !important;
  font-family: "SofiaProLight";
}
.main-headerPad {
  padding: 10px 25px 10px 30px;
}
.modal-dialog {
  max-width: 650px;
}
.sc-fotOHu {
  display: none !important;
}
.Cbox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0px 30px 0px;
}

.welcomeCard-box {
  padding: 40px 0px;
}
.r-field {
  width: 45% !important;
  position: relative;
}
.r-btn {
  padding: 10px 100px !important;
}
.r-terms {
  margin-bottom: 20px;
}
.r-terms input {
  margin-right: 10px;
}
.r-error {
  color: #ed4f2d;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  bottom: -2px;
  left: 0;
}
.Dcsv {
  display: flex;
  justify-content: space-between;
}
.rdt_TableCell {
  color: #575757 !important;
  font-family: "SofiaProLight";
  border: 0 none #d4d4d4 !important;
  border-right: 1px solid #d4d4d4 !important;
  font-size: 14px !important;
  padding: 13px 8px !important;
}
.t-Search {
  margin-top: 30px;
  margin-bottom: 10px;
}
.tbox-filter {
  width: 65%;
  display: flex;
}
.t-searchbox {
  width: 34%;

  padding: 9px 8px 9px 8px !important;
  font-size: 13px;
}
.search-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.csv-wrap {
  margin-top: 4px;
}
.t-btn {
  padding: 10px 28px !important;
  font-size: 13px !important;
}
.t-btn :hover {
  color: #fff !important;
}

.dropdown-t {
  position: relative;
  display: inline-block;
  color: #575757 !important;
  padding: 20px;
}
.dropdown-content-t label {
  display: flex;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
  align-items: center;
}
.dropdown-content-t input {
  margin-right: 10px;
}

.dropdown-content-t {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  left: -61px;
}

.dropdown-t:hover .dropdown-content-t {
  display: block;
}

html,
body {
  height: 100%;
  font-family: "SofiaProRegular", sans-serif;
  color: #000;
}
a,
:hover,
:focus,
.chartBox svg {
  -webkit-transition: all 0.5s ease-in-out !important;
  -moz-transition: all 0.5s ease-in-out !important;
  -ms-transition: all 0.5s ease-in-out !important;
  -o-transition: all 0.5s ease-in-out !important;
  transition: all 0.5s ease-in-out !important;
}
button:hover,
button:focus {
  outline: none;
}
a:hover,
a:focus {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol {
  margin: 0;
}
div#root {
  min-height: 100%;
  display: flex;
  width: 100%;
}
.App {
  min-height: 100%;
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #2a95cb;
  -webkit-border-radius: 0;
  border-left: 1px solid #888;
}
::-webkit-scrollbar-track-piece {
  background-color: #ecedf1;
  -webkit-border-radius: 0;
  border-left: 1px solid #888;
}
.sidebar {
  position: fixed;
  height: 100%;
  width: 330px;
  /*box-shadow: 0px 0px 18px -5px #dadce0;*/
  z-index: 9999999;
  transition: all 0.5s ease-in-out;
}
.sidebar-body {
  display: flex;
  height: 100%;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(27, 110, 123) 100%,
    rgb(40, 198, 117) 0%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(27, 110, 123) 100%,
    rgb(40, 198, 117) 0%
  );
  background-image: linear-gradient(
    90deg,
    rgb(27, 110, 123) 100%,
    rgb(40, 198, 117) 0%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(27, 110, 123) 100%,
    rgb(40, 198, 117) 0%
  );
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 60px;
}
.first-login .sidebar-body {
  /*width:80px;*/
}
.sidebar-right {
  height: 100%;
  background: #ffffff;
  width: 270px;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.sidebar-left {
  height: 100%;
  width: 60px;
  z-index: 999999;
  background-image: -ms-linear-gradient(
    -90deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -moz-linear-gradient(
    -90deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -webkit-linear-gradient(
    -90deg,
    #735AFF,
    #725AFE
  );
}
.sidebar-left .logo {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dadce069;
  border-right: 1px solid #dadce069;
  min-width: 60px;
}
.sidebar-icons li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0;
  min-height: 55px;
  cursor: pointer;
  position: relative;
}
.sidebar-icons li a svg {
  color: #fff;
}
.sidebar-icons li a:hover:before {
  position: absolute;
  text-transform: capitalize;
  left: 80%;
  top: 50%;
  padding: 7px 16px;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 80px 0px rgba(3, 27, 78, 0.24);
  transform: translateY(-50%);
  color: #000000;
  font-weight: 500;
  width: 150px;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 9999;
}
.sidebar-icons li a#dashboard:hover:before {
  content: "Control Panel";
}
.sidebar-icons li a#coupons:hover:before {
  content: "Coupons";
}
.sidebar-icons li a#vpn-mgmt:hover:before {
  content: "VPN Management";
}
.sidebar-icons li a#Generate:hover:before {
  content: "Integration";
}
.sidebar-icons li a#billing:hover:before {
  content: "Billing & History";
}
.sidebar-icons li a#vpn:hover:before {
  content: "VPN";
}
.sidebar-icons li a#developer:hover:before {
  content: "Developer Tools";
}
.sidebar-icons li a#settings:hover:before {
  content: "Account Settings";
}
.sidebar-icons li a:hover,
.sidebar-icons li a.active {
  background: #c6c6c67d;
}
.fa-2x {
  font-size: 1.3rem !important;
}
.sidebar-right-head {
  padding: 20px 10px;
  border-bottom: 1px solid #dadce069;
  min-height: 84px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.sidebar-right-head h4 {
  font-size: 15px;
  color: #a3a7b4;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
}
.sidebar-right-links li a {
  display: flex;
  align-items: center;
  padding: 0px 30px 0px 30px;
  font-size: 15px;
  color: #575757;
  font-weight: 500;
  text-transform: capitalize;
  min-height: 55px;
}
.verify-btn button {
  padding: 15px 47px !important;
}
.verify-btn h3 {
  margin: 0 0 35px !important;
}
.sidebar-right-links li a.active,
.sidebar-right-links li a:hover {
  background: #f7f7f8;
  color: #575757;
}
.sidebar-right-links li a:hover {
  /* padding-left:40px; */
}
.sidebar-right-links li a.active {
  font-weight: 500;
}
.sidebar-right > div {
  width: 100%;
}
.sidebar-right-links {
  height: calc(100% - 89px);
  overflow: auto;
  background: #ffffff;
}
.sidebar-right-links li a i {
  margin-left: 10px;
  margin-top: 6px;
}
ul.list-unstyled.childul {
  padding: 0 20px;
}
ul.list-unstyled.childul a {
}
.startbar-right-end {
  border-top: 1px solid #dadce0;
}
.account-status .dropdown {
  background: #ffffff url(assets/img/arrow.png) no-repeat;
  -webkit-background-size: auto;
  background-size: auto;
  background-position: 95% center;
}
.account-status .dropdown > button {
  background: transparent;
  border: 0;
  outline: 0;
  color: #000000;
  font-weight: 500;
  text-align: left;
  display: block;
  width: 100%;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 0 30px;
  height: 90px;
}
.account-status .dropdown > button:hover,
.account-status .dropdown > button:active,
.account-status .dropdown > button:focus {
  background: transparent;
  border: 0;
  outline: 0;
}
.account-status .dropdown > button span {
  color: #000000;
  display: block;
  margin-top: 2px;
}
.sidebar-right-start {
  height: 100%;
  overflow: auto;
}
.sidebar-right-start > div {
  height: 100%;
  overflow: auto;
}
.account-status .btn-secondary:not(:disabled):not(.disabled).active,
.account-status .btn-secondary:not(:disabled):not(.disabled):active,
.account-status .show > .btn-secondary.dropdown-toggle,
.account-status button:hover {
  color: #000000;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.account-status .btn-secondary.focus,
.account-status .btn-secondary:focus {
  box-shadow: none;
  background-color: #eff1f5;
}
.account-status .dropdown-menu {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 0px 0px 80px 0px rgba(3, 27, 78, 0.14); */
  left: inherit !important;
  right: -20px;
  top: 20px !important;
  padding: 0;
}
.account-status .dropdown-menu h6.dropdown-header {
  color: #2399f1;
  font-weight: 500;
  font-size: 15px;
  padding: 20px 60px 20px 40px;
  position: relative;
}
.account-status .dropdown-menu h6.dropdown-header:before {
  font-family: FontAwesome;
  content: "\f00c";
  color: #2399f1;
  top: 50%;
  left: 15px;
  position: absolute;
  transform: translateY(-50%);
  font-weight: 100;
}
.account-status .dropdown-menu button {
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  padding: 20px 60px 20px 40px;
}
.dropdown-divider {
  height: 0;
  margin: 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.account-status .dropdown > button:hover + .dropdown-menu,
.account-status .dropdown-menu[x-placement^="top"]:hover {
}
.App {
  min-height: 100%;
}
.dashboard-body {
  margin-left: 330px;
  width: calc(100% - 330px);
  padding: 50px 30px 50px 70px;
  min-height: 100%;
}
.login-spinner {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999999999999999999999;
  background: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accountMain {
  font-family: "Lato", sans-serif;
  height: calc(100vh - 3px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accountMain p,
.accountMain label,
.accountMain .form-control,
.accountMain h3,
.accountMain a,
.accountMain h4 {
  font-family: "Lato", sans-serif;
}
.accountMain.hasBackground {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(245, 249, 252) 0%,
    rgb(210, 232, 247) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(245, 249, 252) 0%,
    rgb(210, 232, 247) 100%
  );
  background-image: linear-gradient(
    90deg,
    rgb(245, 249, 252) 0%,
    rgb(210, 232, 247) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(245, 249, 252) 0%,
    rgb(210, 232, 247) 100%
  );
  /*background-image: url('assets/img/atom-bg-left.png'), url('assets/img/atom-bg-right.png') ;*/
  /*background-size:auto;*/
  /*background-repeat:no-repeat;*/
  /*background-position: top left, top right;*/
  /*background-color: #D2E8F7;*/
  /*background-attachment: fixed;*/
}
.not-supported-wrapper {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(245, 249, 252) 0%,
    rgb(210, 232, 247) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(245, 249, 252) 0%,
    rgb(210, 232, 247) 100%
  );
  background-image: linear-gradient(
    90deg,
    rgb(245, 249, 252) 0%,
    rgb(210, 232, 247) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(245, 249, 252) 0%,
    rgb(210, 232, 247) 100%
  );
  height: 100vh;
  position: relative;
}
.not-supported-wrapper > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  -webkit-box-shadow: 0px 0px 20px 0px #bdbcbc3b;
  -moz-box-shadow: 0px 0px 20px 0px #bdbcbc3b;
  box-shadow: 0px 0px 20px 0px #bdbcbc3b;
  padding: 40px 70px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.not-supported-wrapper > div {
  text-align: center;
}
.accountMain > .container {
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*-webkit-transform: translate(-50%, -50%);*/
  /*-moz-transform: translate(-50%, -50%);*/
  /*-ms-transform: translate(-50%, -50%);*/
  /*-o-transform: translate(-50%, -50%);*/
  /*transform: translate(-50%, -50%);*/
}
.accountMain p,
.accountMain label,
.accountMain .form-control,
.accountMain h3,
.accountMain a,
.accountMain h4 {
  font-family: "Lato", sans-serif;
}
.accountBox {
  border-style: solid;
  border-width: 1.5px;
  border-color: transparent;
  border-radius: 8px;
  padding: 60px 40px;
  background: white;
  box-shadow: 0px 0px 20px 0px #bdbcbc3b;
}
.accountBox:hover {
  box-shadow: 0px 0px 30px 5px #bdbcbc3b;
}
.accountHead img {
  width: 130px;
  margin: auto;
}
.accountHead {
  text-align: center;
}
.accountBox h3 {
  font-size: 25px;
  color: rgb(42, 42, 42);
  font-weight: bold;
  margin: 35px 0 0;
}
.accountBox p {
  font-size: 15px;
  color: #000000;
  line-height: 21px;
  margin-top: 12px;
}
.accountBody .form-control {
  border-style: solid;
  border-width: 2px;
  border-color: #dee4e9;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  height: 40px;
  width: 100%;
  padding: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}
.accountBox a {
  font-size: 14px;
  color: #001f79;
  font-weight: 600;
}
.accountBox button {
  border-style: solid;
  border-width: 1px;
  background-image: -moz-linear-gradient(
    45deg,
    rgb(40, 198, 117) 0%,
    rgb(27, 110, 123) 100%
  );
  background-image: -webkit-linear-gradient(
    45deg,
    rgb(40, 198, 117) 0%,
    rgb(27, 110, 123) 100%
  );
  background-image: linear-gradient(
    45deg,
    rgb(40, 198, 117) 0%,
    rgb(27, 110, 123) 100%
  );
  background-image: -ms-linear-gradient(
    45deg,
    rgb(40, 198, 117) 0%,
    rgb(27, 110, 123) 100%
  );
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  padding: 7px 47px;
  font-size: 13px;
  letter-spacing: 1px;
  cursor: pointer;
}
.accountBody {
  margin: 30px 0 40px;
}
.accountFooter a {
  padding: 5px 0;
}
.accountBody p {
  margin-top: 10px;
}
.abLogo {
  margin-bottom: 50px;
}
.abLogo img {
  width: 140px;
}
.confirmBox {
  padding-top: 30px !important;
}
.footerLinks li a,
.footerLinks p {
  font-size: 14px !important;
  color: rgb(151, 151, 151) !important;
  font-weight: 500 !important;
  padding: 2px 0 !important;
}
.footerLinks {
  margin-top: 10px;
}
.footerLinks li a:hover,
.footerLinks li a:focus {
  color: #0099e5;
}
.footerLinks ul {
  margin: 0;
}
.accountBox label span {
  height: 4px;
  width: 4px;
  display: inline-block;
  background: red;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: relative;
  top: -3px;
  margin-left: 3px;
}
span.errorText {
  color: #ed4f2d;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  bottom: -23px;
  left: 0;
}
.accountBody textarea.form-control {
  height: inherit !important;
  margin-top: 10px;
}
.accountBox button:hover {
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(27, 110, 123) 100%,
    rgb(40, 198, 117) 0%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(27, 110, 123) 100%,
    rgb(40, 198, 117) 0%
  );
  background-image: linear-gradient(
    90deg,
    rgb(27, 110, 123) 100%,
    rgb(40, 198, 117) 0%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(27, 110, 123) 100%,
    rgb(40, 198, 117) 0%
  );
}
.accountBox button:focus {
  outline: 0;
}
.formBox .form-group,
.forgetFormBox .form-group {
  position: relative;
}
.formBox .form-group .errorText,
.forgetFormBox .form-group .errorText {
  opacity: 0;
  /*display: none;*/
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.formBox .form-group .errorText.show {
  opacity: 1;
}
.resetBox .formBox {
  margin-bottom: 20px;
}
.accountShower {
  cursor: pointer;
  display: table;
  border: 1px solid #dee4e9;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  margin: 0 auto 20px;
  box-shadow: 0px 0px 15px -1px #dee4e9;
}
.accountShower h4 {
  color: #000000;
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  padding: 10px 20px;
}
.accountShower h4 i {
  margin-right: 3px;
}
.accountBox p a {
  text-decoration: underline;
}
.accountBody .form-control:focus {
  border: 0;
  border-bottom: 2px solid #001f79;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: none;
}
.formBox.show-email,
.formBox.show-password {
  display: block;
}
.formBox.hide-email,
.formBox.hide-password {
  display: none;
}
.alert-danger {
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600;
}
.Toastify__toast {
  font-family: "Lato", sans-serif !important;
  font-size: 13px;
  padding-left: 20px !important;
  letter-spacing: 1px;
}
.Toastify__toast {
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  padding-left: 50px !important;
}
.Toastify__toast.Toastify__toast--error:before {
  position: absolute;
  content: "\f071";
  font-family: FontAwesome;
  color: #fff;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 100%;
  background: #0000002b;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Toastify__toast.Toastify__toast--success {
  background: green !important;
}
.Toastify__toast.Toastify__toast--success:before {
  position: absolute;
  content: "\f058";
  font-family: FontAwesome;
  color: #fff;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 100%;
  background: #0000002b;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Toastify__close-button {
  padding-right: 10px !important;
  padding-top: 10px !important;
  opacity: 1 !important;
}
.fullwidthbutton button {
  width: 100%;
}
.reset-form {
  max-width: 360px;
  width: 100%;
  margin: auto;
}
.blurImg img[loading="false"] {
  filter: blur(0px) !important;
}
.box {
  padding: 20px;
  -webkit-box-shadow: 0px 0px 20px 2px #efecec;
  -moz-box-shadow: 0px 0px 20px 2px #efecec;
  box-shadow: 0px 0px 20px 2px #efecec;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background: #ffffff;
}
.box h3 {
  font-size: 18px;
  margin: 0 0 15px;
  font-weight: 600;
}
.box p {
  font-size: 16px;
  margin: 0;
}
.dashboard-wrapper {
  min-height: 100%;
  /* display: flex; */
  /*background-image: url('assets/img/atom-bg-left.png'), url('assets/img/atom-bg-right.png') ;*/
  /*background-size:auto;*/
  /*background-repeat:no-repeat;*/
  /*background-position: top left, top right;*/
  background-color: #EFECF6;
  background-attachment: fixed;
}
.first-login-head {
  margin-bottom: 60px;
  text-align: center;
}
.first-login-head h3 {
  font-size: 30px;
  color: #000000;
  text-align: center;
  font-weight: 700;
  margin-top: 20px;
}
.first-login-head h5 {
  margin-top: 10px;
  color: #818ba2;
  font-size: 17px;
}
.first-login-box {
  text-align: center;
  padding: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.first-login-box:hover {
  box-shadow: 0px 0px 30px 10px rgba(3, 27, 78, 0.1);
}
.flb-icon {
  margin-bottom: 40px;
}
.flb-icon img {
}
.flb-body h4,
.expired-box h4 {
  color: #000000;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
.flb-body p,
.expired-box p {
  color: #000000;
  margin: 0;
  font-size: 17px;
}
.flb-footer {
  margin-top: 50px;
  display: flex;
}
.flb-footer a {
  border: 2px solid #2399f1;
  color: #2399f1;
  font-weight: 600;
  font-size: 17px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 10px 10px;
  margin: auto;
  flex-basis: 300px;
  display: inline-block;
}

.flb-footer a:hover,
.flb-footer a.active {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  color: #ffffff;
}
.first-login-body .row {
  margin-bottom: 50px;
}
.first-login-body .row:last-child {
  margin-bottom: 0px;
}
.first-login.dashboard-wrapper .sidebar-right {
  /*display: none;*/
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.sidebar.sidebar-toggled {
  width: 60px;
}
.sidebar.sidebar-toggled ~ .dashboard-body {
  margin-left: 80px;
  width: calc(100% - 80px);
  padding: 50px 50px;
}

.sidebar.sidebar-toggled .sidebar-right {
  /* display: block; */
  left: -210px !important;
  /*box-shadow: 0px 0px 18px -5px #dadce0;*/
  z-index: 999999;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  /* right: inherit; */
}
.sidebar.sidebar-toggled .sidebar-right.hover-active,
.sidebar.sidebar-toggled .sidebar-right:hover {
  left: 60px !important;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.sidebar.sidebar-toggled + .dashboard-body {
  margin-left: 60px;
  width: calc(100% - 60px);
}
.sidebar.sidebar-toggled .sidebar-left {
  /* background-image: -moz-linear-gradient( 90deg, rgb(42, 149, 203) 0%, rgb(0, 31, 121) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(42, 149, 203) 0%, rgb(0, 31, 121) 100%);
    background-image: linear-gradient( 360deg, rgb(42, 149, 203) 0%, rgb(0, 31, 121) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(42, 149, 203) 0%, rgb(0, 31, 121) 100%); */
  z-index: 99999999999999999;
}
.sidebar-right {
  left: 60px !important;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
svg.svg_logo {
  height: 60px;
}
.first-login-avatar img {
  max-width: 210px;
}
.sidebar-left-start {
  height: calc(100vh - 55px);
  padding-top: 50px;
}
.sidebar-left-end button.btn.btn-secondary {
  background: none;
  border: none;
  display: block;
  width: 100%;
}
.sidebar-left-end button.btn.btn-secondary:focus {
  outline: 0;
  box-shadow: none;
}
.sidebar-left-end .dropdown-menu {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 0px 0px 80px 0px rgba(3, 27, 78, 0.14); */
  left: inherit !important;
  right: -120px;
  top: 20px;
  padding: 0;
}
.sidebar-left-end .dropdown-item {
  font-size: 14px;
}
.sidebar-left-end .dropdown-item {
  font-size: 14px;
  padding: 10px 1.5rem;
}
.sidebar-left-end .btn-secondary:not(:disabled):not(.disabled).active,
.sidebar-left-end .btn-secondary:not(:disabled):not(.disabled):active,
.sidebar-left-end .show > .btn-secondary.dropdown-toggle,
.sidebar-left-end button:hover {
  color: #000000;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.sidebar-left-end .dropdown-menu h6 {
  padding: 15px 1.5rem;
}
.sidebar-left-end button.btn.btn-secondary img {
  width: 38px;
  height: 38px;
}
.dashboard-body {
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.dashboard-loader {
  position: absolute;
  top: 0px;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: center;
}
.trial-expired-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expired-links {
  margin-top: 40px;
}
.expired-box {
  text-align: center;
}
.expired-box h4 {
  font-size: 35px;
  margin: 30px 0;
}
.expired-box p {
  font-size: 18px;
  color: #000;
}
.expired-links button {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  color: #ffffff;
  font-weight: 500;
  font-size: 17px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 10px 30px;
  width: 260px;
  margin: auto;
  display: inline-block;
  border: 2px solid transparent;
  letter-spacing: 1px;
}
.expired-links ul li:last-child button {
  background-image: none;
  color: #2399f1;
  border: 2px solid #2399f1;
  background: white;
}
.expired-links ul li {
  margin: 0 20px;
}

/*Network preferences css*/
.profilePV {
  background: #073387;
  padding: 15px 30px;
}
.m-0 {
  margin: 0;
}
.profilePV li a {
  color: #fff;
  display: block;
  text-decoration: none;
  padding: 12px 30px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
}
.profilePV li a img,
.pbHead li a img {
  margin-right: 10px;
}
.profilePV li.active a,
.profilePV li a:hover {
  background: #214994;
}
.pbHead {
  background: #fff;
  padding: 0px 50px;
  margin-bottom: 40px;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.08);
}
.nav-tabs .nav-link {
  border: 0 !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: 2px solid transparent !important;
}
.pbHead li a {
  background: none;
  color: #031b4e;
  padding: 20px 40px;
  font-size: 16px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-flex;
  min-height: 68px;
  font-weight: 600;
  letter-spacing: 1px;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: transparent;
}
.pbHead li a svg {
  margin-right: 10px;
}
.pbHead li.active a svg path {
  fill: #2399f1;
  opacity: 1;
}
.pbHead li a.active,
.pbHead li a:hover,
.pbHead li a:focus {
  color: #212121 !important;
  border-color: transparent !important;
  background: none !important;
  border-bottom: 2px solid #2399f1 !important;
}
.orderHistory {
  padding: 30px 0 30px;
}

.orderHistory h1 {
  font-size: 30px;
  color: rgb(3, 27, 78);
  line-height: 1.133;
  margin: 0;
}
.orderHistory a {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 121, 191);
  border-radius: 2px;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  padding: 7px 30px;
  display: inline-flex;
  letter-spacing: 1px;
}
.orderHistory a svg {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}
.orderHistory a:hover {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(33, 137, 217) 50%,
    rgb(35, 153, 241) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(33, 137, 217) 50%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(33, 137, 217) 50%,
    rgb(35, 153, 241) 100%
  );
}
.orderHistory a:hover path {
}
.orderHistory a img {
  margin-right: 10px;
}
.statsBox h1 {
  color: #2399f1;
  font-size: 1.5vw;
  min-height: 34px;
  font-weight: bold;
}
.statsBox h4 {
  font-size: 20px;
  color: rgb(129, 139, 162);
  margin: 10px 0 0 0;
}
.statsBox a {
  font-size: 17px;
  color: rgb(35, 153, 241);
  line-height: 1.2;
  background: #f8f9fb;
  padding: 15px;
  border-top: 1px solid #a9a9a938;
  display: block;
}
.statsBox a:hover {
  color: #ffffff;
  background: rgb(35, 153, 241);
}
.statsBox a span {
  margin-right: 5px;
  display: inline-block;
  background: rgb(35, 153, 241);
  color: white;
  height: 21px;
  width: 21px;
  border-radius: 6px;
  font-weight: bold;
}
.statsBox button {
  font-size: 17px;
  color: rgb(35, 153, 241);
  line-height: 1.2;
  background: #f8f9fb;
  padding: 15px;
  border: 0;
  border-top: 1px solid #a9a9a938;
  display: block;
  width: 100%;
}
.statsBox button:hover {
  color: #ffffff;
  background: rgb(35, 153, 241);
}
.statsBox button span {
  margin-right: 5px;
  display: inline-block;
  background: rgb(35, 153, 241);
  color: white;
  height: 21px;
  width: 21px;
  border-radius: 6px;
  font-weight: bold;
}
.statsBox {
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.statsBox:hover {
  box-shadow: 0px 0px 20px 5px rgba(3, 27, 78, 0.06);
}
.statsBoxBody {
  padding: 50px 30px 53px 30px;
}
.statsBoxFooter {
}
.dashboard-inner-table {
  padding: 30px 0;
}
.dashboard-inner-table .table,
.rdt_Table {
  text-align: center;
  border-top: 1px solid;
  border-color: rgb(218, 220, 224);
  background-color: rgb(255, 255, 255);

  border-left: 1px solid #d4d4d4;
}
.dashboard-inner-table .table th,
.rdt_TableHead .rdt_TableHeadRow > div {
  font-size: 16px;
  font-family: "SofiaProLight";
  color: #b6b6b6;
  text-transform: uppercase;
  padding: 15px 8px !important;
  font-weight: 400;
  border-right: 1px solid rgb(218, 220, 224);
  text-align: left;
}
.dashboard-inner-table .table tr {
}
.rdt_TableBody > div {
  border: 0;
}
.dashboard-inner-table .table td,
.rdt_TableBody > div > div {
  text-align: left;
  border: 1px solid rgb(218, 220, 224);
  padding: 11px 08px !important;
  color: #031b4e;
  font-weight: 500;
  font-size: 16px;
  vertical-align: middle;
}
.dashboard-inner-table .cities span {
  border: 1px solid rgb(218, 220, 224);
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  padding: 5px 40px 5px 15px;
  cursor: pointer;
  background: #fff url(assets/img/network/close.png) no-repeat;
  background-position: 95% center;
  margin: 0px 15px 15px 0;
  display: inline-block;
}
.dashboard-inner-table .cities span:last-child {
  margin: 0;
}
.dashboard-inner-table .cities span:hover {
  background-color: #f8f9fb;
}
.dashboard-inner-table .table td.load {
  vertical-align: middle;
  text-align: center;
  position: relative;
}
.dashboard-inner-table .table td.load span {
  position: absolute;
  left: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.progress-bar {
  background-color: #0099e5;
}
.progress {
  margin-top: 0px;
  margin-bottom: 0;
  height: 5px;
  background: #e2e7ee;
  margin-left: 40px;
}
.isPending tr td {
  background: #fff4e8;
}
.isPendingHead td span {
  height: 8px;
  width: 8px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #fe951d;
  display: inline-block;
  margin-right: 10px;
}
.isPendingHead td {
  font-weight: 600;
  font-size: 17px !important;
  background: #ffffff !important;
}
.protocol {
}
.protocolHead h4 {
  margin: 0;
  font-size: 18px;
  color: rgb(163, 167, 180);
  text-transform: uppercase;
  padding: 20px 40px;
  font-weight: 400;
  border-bottom: 1px solid rgb(218, 220, 224);
}
.protocolBody {
  padding: 20px 40px 20px;
}
.protocolBody li {
  padding: 25px 0px 25px 50px;
  color: #031b4e;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  margin-right: 20px !important;
}
.protocolFoot button {
  font-size: 17px;
  color: rgb(35, 153, 241) !important;
  line-height: 1.2;
  padding: 20px 40px;
  display: block;
  background: none;
  border: 0;
}
tfoot a,
tfoot button {
  background: none;
  border: 0;
  color: rgb(35, 153, 241);
  display: block;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
}
.protocolFoot a span {
  margin-right: 5px;
}
.protocolSec > .row:last-child {
  border: 0;
}
.protocolSec > .row {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgb(218, 220, 224);
}
.protocolSec > .row > div {
  padding: 0;
}
/*.protocolSec:before{*/
/*position:absolute;*/
/*content: '';*/
/*left:50%;*/
/*-webkit-transform: translateX(-50%);*/
/*-moz-transform: translateX(-50%);*/
/*-ms-transform: translateX(-50%);*/
/*-o-transform: translateX(-50%);*/
/*transform: translateX(-50%);*/
/*width:1px;*/
/*background: rgb(218, 220, 224);*/
/*top:0;*/
/*height:100%;*/
/*}*/
.protocolSec {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}

.protocolBody li:before {
  content: "";
  background: url(assets/img/network/protocol.png) no-repeat center center;
  height: 40px;
  width: 40px;
  background-size: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  position: absolute;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.additionalServices li:before {
  background: url(assets/img/network/firewall.png) no-repeat center center;
  background-size: auto;
}
.additionalServices li:nth-child(odd):before,
.protocolBody li:nth-child(odd):before {
  background-color: #2399f1;
}
.additionalServices li:nth-child(even):before,
.protocolBody li:nth-child(even):before {
  background-color: #001f79;
}

.addSection {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}
.addSection h4 {
  margin: 0;
  font-size: 18px;
  color: rgba(66, 84, 122, 0.749);
}
.addSection h4 span {
  font-size: 37px;
  margin-right: 10px;
  font-weight: 300;
  padding: 0px 0 0px 0;
  display: inline-block;
  line-height: 0;
  position: relative;
  top: 6px;
}
.addSectionHead {
  padding: 20px;
  cursor: pointer;
}

.addSectionBody > ul li a {
  display: block;
  position: relative;
  padding-left: 35px;
  text-align: left;
  color: #031b4e;
  font-weight: 500;
  font-size: 16px;
}
.addSectionBody > ul li a img {
  position: absolute;
  top: 0;
  left: 0;
}
.addSectionBody > ul li {
  border-top: 1px solid rgb(218, 220, 224);
  padding: 20px 30px;
}
.addSectionBody {
  display: none;
}
.addSectionBody.show {
  display: block;
}
.goBack a {
  color: #42547a;
  cursor: pointer;
  font-size: 18px;
}
.goBack a img {
  margin-right: 10px;
}
.goBack {
  padding: 20px 30px;
  border-top: 1px solid rgb(218, 220, 224);
}
.listBody,
.listFooter {
  padding: 20px 30px;
  border-top: 1px solid rgb(218, 220, 224);
}
.listBody h4 {
  text-transform: capitalize;
  color: #a3a7b4;
  margin: 0 0 15px;
  font-size: 13px;
}
.listFooter button {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 121, 191);
  border-radius: 2px;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  padding: 7px 30px;
  display: block;
  letter-spacing: 1px;
}
.listFooter a {
  font-size: 17px;
  color: rgb(35, 153, 241);
  line-height: 1.2;
  padding: 15px;
  cursor: pointer;
  display: block;
}
.addSection {
  position: relative;
  z-index: 2;
}
.addSectionBody {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 59px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}
.addCountries,
.addCities,
.addProtocols,
.additionalServicesList {
  position: absolute;
  width: 100%;
  z-index: 3;
  top: 0px;
  background-color: rgb(255, 255, 255);
}
.addCountries,
.addCities,
.addProtocols,
.additionalServicesList {
  display: none;
}
.addList.show {
  display: block;
}
.profileOverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
}
.profileOverlay.show {
  display: block;
}
.addList {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 220, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
  top: 60px;
}
.select2-container--default .select2-selection--multiple {
  border: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  min-height: 45px;
  -webkit-box-shadow: 0px 0px 10px 1px #a9a9a95e;
  -moz-box-shadow: 0px 0px 10px 1px #a9a9a95e;
  box-shadow: 0px 0px 10px 1px #a9a9a95e;
  background: #f2f2f2ab !important;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 0;
  outline: 0;
  background: #f2f2f2ab !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #fff;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px 5px;
  color: rgb(35, 153, 241);
  text-transform: capitalize;
  letter-spacing: 1px;
  border: 2px solid rgb(35, 153, 241);
  font-size: 11px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 5px;
  background: #2399f1;
  height: 16px;
  width: 16px;
  text-align: center;
  border-radius: 100%;
  padding-left: 1px;
  line-height: 15px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #fff;
  background: #000;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 10px 10px;
}
.select2-container--default .select2-search--inline .select2-search__field {
  width: 150% !important;
}
.protocolHead h4 span {
  height: 8px;
  width: 8px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #fe951d;
  display: inline-block;
  margin-right: 10px;
}
.isPendingList .protocolBody {
  background: #fff4e8;
}
.row.isPendingList > div:first-child .protocolBody {
  border-right: 1px solid rgb(218, 220, 224);
}
.loading {
  display: none;
  position: fixed;
  top: 350px;
  left: 50%;
  margin-top: -96px;
  margin-left: -96px;
  background-color: #ccc;
  opacity: 0.85;
  border-radius: 25px;
  width: 192px;
  height: 192px;
  z-index: 99999;
}
.countriesModal h4 {
  border-bottom: 1px solid rgb(218, 220, 224);
  padding: 20px 30px;
  margin: 0;
}
.countriesModal .listBody {
  border: 0;
}
.listFooter li {
  padding: 0 10px 0 0;
}
span.select2-dropdown {
  outline: 0 !important;
  border: 0 !important;
  padding: 20px !important;
  box-shadow: 0px 0px 10px 1px #a9a9a95e !important;
}
.listBody .form-control {
  border: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  min-height: 45px;
  -webkit-box-shadow: 0px 0px 10px 1px #a9a9a95e;
  -moz-box-shadow: 0px 0px 10px 1px #a9a9a95e;
  box-shadow: 0px 0px 10px 1px #a9a9a95e;
  background: #f2f2f2ab !important;
  font-size: 14px;
  text-transform: capitalize;
}
.listBody .form-group {
  margin-bottom: 20px;
}
.statsBoxHead h4 {
  margin: 0;
  font-size: 16px;
  color: rgb(3, 27, 78);
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px 0;
  border-bottom: 1px solid rgb(218, 220, 224);
  letter-spacing: 0px;
}
.statsBox h4 span {
  color: #2399f1;
  font-weight: 600;
}
.accountStats > .row {
  margin-bottom: 30px;
}
.accountStats > .row:last-child {
  margin-bottom: 20px;
}

.notification-wrap {
  padding: 11px 25px !important;
  /* border-left: 4px solid  #0896ae; */
  border-left: 4px solid #b1b1b1;
}
.notification-wrap p {
  margin-bottom: 0px !important;
  font-size: 13px !important;
}

.notification-wrap h5 {
  color: #000;
}
.notification-content {
  padding-top: 5px;
}
.notify-head h1 {
  margin-bottom: 60px !important;
}
.notifications-wrap-head {
  margin-bottom: 10px;
  cursor: pointer;
}
.notification-icon {
  position: relative;
}
.notify-dropdown {
  position: absolute;
  top: 46px;
  z-index: 999;
  width: 330px;
  height: 350px;
  max-height: 350px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  left: -259px;
  box-shadow: 0 10px 25px 0 rgb(33 36 50 / 13%);
}
.notify-dropdown::-webkit-scrollbar {
  border: none;
}
.notify-dropdown p {
  margin-bottom: 0px;
}
.no-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.notifications-item {
  border-bottom: 1px solid #eff1f5;
  padding: 20px 15px 15px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 1.6;
  cursor: pointer;
}
.notifications-item:hover {
  background-color: #f5fcff;
}
.notification-menu {
  justify-content: space-between;
  align-items: end;
  width: 27%;
  padding-right: 12px;
  display: flex;
  text-align: end;
}

.noti-menu {
  width: 76%;
}
.notification-menu p {
  font-size: 15px;
  color: #575757;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
}
.active-noti {
  font-weight: 600;
}
.unread-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.unread-bar p {
  color: #646777;
  font-size: 14px;
  line-height: 16px;
}
.unread-bar p span {
  color: #bbbbc2;

  padding: 0;
  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
  background: transparent;
}
.unread-bar span {
  cursor: pointer;
}
.loadmore-wrap {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.text {
  width: 100%;
  display: flex;
  align-items: center;
}
.topbar__collapse-img-wrap {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #17d5b1;
  color: #fff;
  font-size: 16px;
  font-family: cursive;
  min-width: 40px;
  min-height: 40px;
}
.topbar__collapse-img {
  width: 100%;
}
.notifity-content {
  padding-left: 15px;
}
.notify-drop-title {
  font-weight: 600;
}
.notifity-content p span {
  color: #1876f2;
  font-weight: 500;
}
.topbar-collapse-link {
  display: block;
  padding: 10px;
  text-transform: uppercase;
  color: #4ce1b6 !important;
  transition: 0.3s;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  position: absolute;

  width: 100%;
}
@media only screen and (min-width: 1100px) {
  .dashboard-inner-table .table td.load {
    width: 200px;
  }
  .dashboard-inner-table .table tr td:first-child {
    width: 300px;
  }
}
.addSectionBody > ul > li {
  cursor: pointer;
}
.addSectionBody > ul > li > img {
  margin-right: 10px;
}
span.select2 {
  width: 100% !important;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: none !important;
  border-radius: 4px;
  padding-left: 10px;
  cursor: text;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #ffffff !important;
  border: none;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 10px !important;
  margin-top: 9px !important;
  margin-bottom: 9px;
  padding: 5px 5px !important;
  font-size: 15px;
  color: #000;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: none;
  outline: 0;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 5px !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 0;
}
.select2-container .select2-search--inline {
  float: left;
  height: 40px;
  margin-top: 9px;
}
.css-1alnv5e:hover {
  background-color: #2399f1 !important;
  color: #fff !important;
}
.css-yk16xz-control {
  border: 0 !important;
  background: #efebeb !important;
}
.modal-open .modal {
  overflow-x: hidden;
}
body.modal-open {
  overflow-y: auto;
  padding-right: 0 !important;
}
.countriesCitiesModal button {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 121, 191);
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  color: #ffffff;
  padding: 9px 0px;
  display: block;
  letter-spacing: 1px;
  font-size: 16px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 30px;
}
.countriesCitiesModal h4 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #000;
  text-transform: uppercase;
}
.countriesCitiesModal .listBody {
  border-top: 0;
}
.modal .close.old {
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  opacity: 1;
  color: white !important;
  height: 30px;
  width: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/*@keyframes fadeIn { from { opacity: 0; } to { opacity: 1; } }*/

/*.tab-pane.active { animation-name: fadeIn; animation-duration: 300ms; animation-timing-function: linear; }*/

.modal .col-md-4 {
  display: flex;
  align-items: center;
}
.ip_country {
  max-height: 400px;
  overflow-y: auto;
}

.ip_country .row {
  margin: 0;
}

.ip_country .row > div {
  padding: 0 10px;
}
.ip_country label {
  font-size: 13px;
}
.dedicatedProtocol:before {
  display: none;
}
.dashboard-stats .statsBoxBody h1 {
  color: #2399f1;
  font-size: 1.5vw;
  min-height: 34px;
  text-transform: uppercase;
}
.dashboard-stats .statsBoxBody h4 {
  font-size: 1vw;
  margin-top: 10px;
  text-transform: capitalize;
}
.dashboard-stats > .row {
  display: flex;
}
.dashboard-stats > .row .statsBox {
  height: 100%;
}
.chartBox svg > g:last-child > text {
  display: none;
}
.dashboard-charts {
  margin-top: 50px;
}
.chartBox h3 {
  margin: 0;
  font-size: 22px;
  color: #031b4e;
  background: white;
  display: block;
  padding: 20px 0;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
  border-bottom: 1px solid rgb(218, 220, 224);
}
.chartBox svg g[class$="parentgroup"] + g {
  display: none;
}
.white-space {
  background: white;
  padding: 20px 0;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}
.chartBox svg rect[fill="#5d62b5"] {
  fill: #2399f1 !important;
}
.chartBox svg path[fill="#5d62b5"] {
  fill: rgba(35, 153, 241, 1);
  fill-opacity: 1;
}
.dashboard-charts .row div .chartBox {
  margin-bottom: 40px;
}
.dashboard-charts .row div .chartBox:last-child {
  margin-bottom: 0px;
}
.chartBox table th {
  color: #a3a7b4;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  padding: 20px 20px;
}

.chartBox td {
  color: #031b4e;
  font-weight: 600;
  font-size: 15px;
  padding: 15px 20px;
}

.chartBox {
  overflow: auto;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}

.chartBox table.table {
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
  margin-bottom: 0;
}
.dashboard-charts .row {
  margin-bottom: 40px;
}
.dashboard-charts .row:last-child {
  margin-bottom: 0px;
}
.bandwidthChart .white-space {
  background-image: -ms-linear-gradient(
    -90deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -moz-linear-gradient(
    -90deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -webkit-linear-gradient(
    -90deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
}
.bandwidthChart svg g[class$="parentgroup"] > g:first-child rect {
  fill: transparent !important;
  background-color: transparent !important;
}
.bandwidthChart svg {
  background-color: transparent !important;
}
.bandwidthChart svg path {
}
.chartBox.bandwidthChart svg path[fill="#5d62b5"] {
  fill: #ffffff4d;
}
.chartBox.bandwidthChart svg text {
  fill: #fff !important;
}
.chartBox g[class$="-plot-group"] path {
  stroke: #031b4e;
  fill: #031b4e;
}
.chartBox g[class$="common-elems-group"] path {
  stroke: #0099e5;
}
.chartBox.bandwidthChart g[class$="-plot-group"] path {
  stroke: #ffdd00;
  fill: #ffdd00;
}
.chartBox.bandwidthChart g[class$="common-elems-group"] path {
  stroke: #ffdd00;
}
.dashboard-header h1 {
  color: #031b4e;
  font-size: 25px;
  padding: 5px 0;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
}
.dashboard-header h1 span {
  background: #2399f1;
  color: #fff;
  padding: 5px 17px;
  font-size: 14px;
  border-radius: 50px;
  margin-left: 8px;
  display: flex;
  align-self: center;
  text-transform: uppercase;
}
.dashboard-header button,
.dashboard-header a {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 121, 191);
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  color: white;
  padding: 6px 30px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 50px;
  position: relative;
  top: 4px;
  border: 0;
  display: table;
}
.dashboard-header button:hover,
.dashboard-header a:hover {
  background-image: -moz-linear-gradient(
    -370deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 60%
  );
  background-image: -webkit-linear-gradient(
    -370deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 60%
  );
  background-image: linear-gradient(
    -370deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 60%
  );
  background-image: -ms-linear-gradient(
    -370deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 60%
  );
}

.dashboard-header button i,
.dashboard-header a i {
  margin-right: 5px;
}
.sidebar-toggle {
  position: absolute;
  left: inherit;
  z-index: 9999;
  right: -45px;
  top: 70px;
}
.sidebar-toggle button {
  background: none;
  border: 0;
  color: #fff;
  padding: 1px 0;
  font-size: 16px;
  background: #38176F;
  height: 30px;
  width: 30px;
  border-radius: 100%;
}
.sidebar-toggle button:hover,
.sidebar-toggle button:focus {
  outline: 0;
}
.sidebar-right {
  border-right: 1px solid #dadce0;
}
.sideBarBody {
  position: relative;
}
.sidebar:after {
  height: 100%;
  width: 30px;
  right: -30px;
  background: #fff;
  position: absolute;
  content: "";
  top: 0;
}
.sidebar:hover {
  z-index: 99999999999999999999999999999999;
}

button {
}

.dashboard-inner-table .blue-button button {
  color: #2399f1;
  font-weight: 600;
}

.dashboard-inner-table button {
  background: none;
  border: 0;
  padding: 3px 0px;
  border-radius: 40px;
}

.postback-table span {
  color: #a3a7b4;
}

.postback-table button {
  padding: 3px 30px;
}

.subscribed-button button {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(218, 220, 224);
  background-color: rgb(240, 241, 243);
  color: #a3a7b4;
}

.postback-table button i {
  margin-right: 5px;
}

.modal-dialog.dashboard-modal {
}

.modal-dialog.dashboard-modal .modal-body {
  padding: 0;
}

.dashboard-modal-head {
  background: #063185;
  padding: 20px 30px;
}

.dashboard-modal-head h3 {
  color: white;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.dashboard-modal-body {
  padding: 20px 30px;
}

.dashboard-modal-body h2 {
  color: #354052;
  font-size: 23px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}

.dashboard-modal-body h2 span {
  color: #063185;
  font-weight: bold;
}

.dashboard-modal-body p {
  color: #a3a7b4;
  font-size: 15px;
}
.dashboard-modal-body input.form-control {
  border-radius: 0;
  color: black;
  font-weight: 500;
  font-size: 14px;
  height: 50px;
}
.dashboard-modal-footer {
  padding: 0px 30px 30px;
}
.dashboard-modal-footer button {
  border: 1px solid #063185;
  color: #063185;
  background: none;
  padding: 10px 10px;
  width: 100%;
}
.dashboard-modal-footer button:hover {
  background: #063185;
  color: #fff;
}
.dashboard-modal-footer ul {
  display: flex;
}
.dashboard-modal-footer ul li.list-inline-item {
  flex: 2;
}
.modal-dialog.dashboard-modal .modal-content {
  border-radius: 0;
  border: 0;
}
body.modal-open .sidebar {
  z-index: 1;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.res-table {
  overflow-x: auto;
}
.table-key input.form-control {
  width: 150px;
  border: 0;
  color: black;
  font-weight: 600;
  padding: 0;
}
.key-table th:first-child,
.key-table td:first-child {
  width: 15px !important;
}
.table-key .form-control:disabled,
.table-key .form-control[readonly] {
  background-color: transparent;
  opacity: 1;
  color: black;
  font-weight: 600;
}
.table-key {
  position: relative;
  padding-right: 30px;
}
button.show-key {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #2399f1;
  padding: 0;
}
.key-box-head h3 {
  margin-bottom: 30px;
  color: #031b4e;
}
.key-actions {
  border: 1px solid rgb(218, 220, 224);
  background: white;
  padding: 15px 0;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}

.key-actions li button {
  color: #031b4e;
  padding: 0 20px;
}
.key-actions li button:hover,
.key-actions li button:focus {
  color: #2399f1;
  outline: 0;
}
.key-actions li button i {
  margin-right: 8px;
}
table.table.key-table.delete-key input.form-control {
  color: #ed1c24;
}
.key-actions .list-inline-item:not(:last-child) {
  margin-right: 0;
}
.modal-ul li {
  margin-bottom: 10px;
  clear: both;
}
.modal-ul li button {
  margin-left: 0;
  background: none;
  border: 0;
  color: #ed1c24;
  font-size: 20px;
  font-weight: bold;
  float: left;
  position: relative;
  top: -4px;
}
.modal-ul li span {
  color: #a3a7b4;
  font-size: 18px;
}

.modal-ul {
  margin-bottom: 10px;
}
.dashboard-modal-footer button[disabled],
.dashboard-modal-footer button[disabled]:hover {
  opacity: 0.2;
  background: gray;
  color: #fff;
}
table.table.key-table .blue-button button {
  padding: 0;
}

table.table.key-table .blue-button button:hover {
  color: #063185;
}

.permission-checkbox label {
  position: relative;
  margin: 0;
}
.permission-checkbox .checkbox {
  display: none;
  opacity: 0;
}

.permission-checkbox .check {
  display: block;
  width: 85px;
  height: 30px;
  border: 0;
  border-radius: 40px;
  transition: border-color 0.5s ease-out;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px #a3a3a3;
}
.permission-checkbox .check::before {
  content: "";
  left: 1px;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px #a3a3a3;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transition-property: box-shadow, background-color, -webkit-transform;
  transition-property: box-shadow, background-color, transform;
  transition-property: box-shadow, background-color, transform,
    -webkit-transform;
}

.permission-checkbox .checkbox:checked ~ .check::before {
  box-shadow: inset 0 0 0 3px #fff;
  background-color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(58px);
  transform: translateY(-50%) translateX(58px);
}
.permission-checkbox span {
  background: white !important;
}
.permission-checkbox .checkbox:checked ~ .check {
  box-shadow: 0px 0px 10px 0px #a3a3a3;
  background: #0099e5 !important;
}

.permission-checkbox .text {
  display: block;
  position: absolute;
  color: #031b4e;
  top: 50%;
  opacity: 0;
  font-size: 20px;
  transition: all 0.5s ease;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.permission-checkbox .off {
  right: -93px;
  -webkit-transform: translateY(-60px);
  transform: translateY(-60px);
  color: #222;
}

.permission-checkbox .on {
  right: -68px;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
}

.permission-checkbox .checkbox:checked ~ .on {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.permission-checkbox .checkbox:not(:checked) ~ .off {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.dashboard-inner-table .table td ul li {
  vertical-align: middle !important;
  margin-right: 20px !important;
}
.permission-box {
  max-height: 373px;
  overflow: auto;
  padding: 0;
  margin-top: 30px;
}
.permission-box.dashboard-inner-table table.table {
  margin-bottom: 0;
}
.no-data-found {
  background: #fff;
  text-align: center;
  padding-bottom: 40px;
}
.no-data-found img {
}
.no-data-found h4 {
  color: #031b4e;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.manage-account table.table button.btn.btn-secondary {
  color: #000;
  width: 30px;
  height: 30px;
}

.manage-account table.table button.btn.btn-secondary:hover,
.manage-account table.table button.btn.btn-secondary:focus {
  background: #2399f1;
  color: #fff;
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
}
.res-table {
  overflow-y: visible !important;
}
@media (max-width: 767px) {
  .res-table .dropdown-menu {
    position: static !important;
  }
  .accountMain {
    height: 100% !important;
  }
  .r-btn {
    padding: 12px 40px !important;
    width: 80%;
  }
}
@media (min-width: 768px) {
  .res-table {
    overflow: inherit;
  }
}
.dashboard-inner-table .dropdown-menu {
  right: 41%;
  left: inherit !important;
  border: 0;
  /* box-shadow: 0px 0px 20px 0px rgba(212, 202, 202, 0.48); */
  margin: 0;
  padding: 0;
  border-radius: 0;
}
.dashboard-inner-table .dropdown-menu > * {
  padding: 8px 10px;
  font-size: 14px;
  color: #000;
  border-radius: 0;
  display: block;
  width: 100%;
  font-weight: 500;
  text-align: left;
}
.dashboard-inner-table .dropdown-menu > *:hover,
.dashboard-inner-table .dropdown-menu > *:focus {
  background: #2399f1;
  color: #fff;
}
.dashboard-inner-body td span,
.rdt_TableBody span {
  padding: 5px 10px;
  border-radius: 40px;
  min-width: 75px;
  text-align: center;
  display: block;
  color: white;
  background: #2399f1;
}
span.expired {
  background: red !important;
}

span.active {
  background: green !important;
}
.dashboard-inner-table .countries span {
  border-radius: 40px;
  padding: 2px 15px 2px 15px;
  cursor: pointer;
  margin: 0px 15px 15px 0;
  display: inline-block;
  background: #2399f1;
  color: #fff;
  font-weight: 500;
}
.vpnHeader {
  margin-bottom: 30px;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.08);
  padding: 20px 40px;
}

.vpnHeader h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.vpnHeader h4 {
  font-size: 16px;
  color: rgb(163, 167, 180);
}

.vpnHeader h4 span,
.vpnHeader h1 span {
  background: #2399f1;
  color: #fff;
  padding: 5px 17px;
  font-size: 14px;
  border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
}
.vpnHeader li.list-inline-item {
  margin-right: 23px;
}
.modalVpnHead {
  padding: 20px 30px 0px 30px;
}

.modalVpnHead h4 {
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.1);
  padding: 20px 20px;
  font-size: 15px;
}
.dashboard-modal-body p span {
  background: #2399f1;
  color: #fff;
  padding: 3px 10px;
  display: inline-block;
  border-radius: 40px;
  font-size: 13px;
}
.vpn-status {
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.1);
  padding: 20px;
}

.vpn-status h4 {
  font-size: 15px;
  margin-bottom: 10px;
}

.vpn-status h4:last-child {
  margin-bottom: 0;
}

.vpn-status h4 span {
  background: #2399f1;
  color: #fff;
  padding: 3px 10px;
  display: inline-block;
  border-radius: 40px;
  font-size: 13px;
  margin-left: 10px;
}
.dashboard-form-body {
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
  background: white;
  padding: 30px;
}

.dashboard-form-body label {
  color: #000;
  font-size: 14px;
  text-transform: capitalize;
}

.dashboard-form-body .form-control,
.dashboard-form-body .css-yk16xz-control {
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 13px !important;
  height: 50px !important;
  box-shadow: 0px 0px 4px -1px #a3a3a3;
  background: none !important;
}
.dashboard-form-body textarea.form-control {
  height: auto !important;
  padding-top: 20px;
}
.dashboard-form-body .css-2b097c-container:focus {
  outline: 0;
  border: 0;
}
.form-check-inline {
  position: relative;
}

.form-check-inline input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #2399f1;
  color: #ffffff;
  top: 10px;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 7px;
  outline: none;
}
.dashboard-form-body input[type="checkbox"] {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.form-check-inline input:checked::before {
  position: absolute;
  font: 11px/1 "Open Sans", sans-serif;
  left: 7px;
  top: 5px;
  content: "\02143";
  transform: rotate(40deg);
}
.form-check-inline input:hover {
  background-color: rgba(35, 153, 241, 0.67);
}
.form-check-inline input:checked {
  background-color: #2399f1;
}
.dashboard-form-body label.form-check-label {
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.dashboard-form-footer button {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 121, 191);
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  color: white;
  padding: 5px 30px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 50px;
  text-transform: uppercase;
}
.dashboard-form-footer button:hover {
  background-image: -moz-linear-gradient(
    -370deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 60%
  );
  background-image: -webkit-linear-gradient(
    -370deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 60%
  );
  background-image: linear-gradient(
    -370deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 60%
  );
  background-image: -ms-linear-gradient(
    -370deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 60%
  );
}
.dashboard-form-footer {
  margin-top: 30px;
}
.accountType {
  display: none;
}
.accountType.show {
  display: block;
}
.css-2b097c-container * {
  transition: none !important;
}
.dashboard-form-body input[id*="react-select"] {
  width: auto !important;
  font-size: 14px !important;
}
.css-1rhbuit-multiValue {
  background: #2399f1 !important;
  color: #fff;
}
.css-1rhbuit-multiValue * {
  color: #fff !important;
  font-size: 12px !important;
}
.dashboard-header {
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}
.dashboard-header h4 {
  margin-top: 15px;
  font-size: 17px;
}
.dashboard-header h4 span {
  background: #2399f1;
  color: #fff;
  padding: 5px 13px;
  display: inline-block;
  border-radius: 40px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 1;
  text-transform: capitalize;
}
.dashboard-inner-table .table td span {
  background: #2399f1;
  color: #fff;
  padding: 4px 15px;
  display: inline-block;
  border-radius: 40px;
  margin: 5px;
}
.dashboard-inner-table .table td span:last-child {
}
.css-1pahdxg-control {
  box-shadow: 0px 0px 4px -1px #a3a3a3 !important;
  outline: none !important;
  border: 0 !important;
  height: 50px !important;
}
.css-1wa3eu0-placeholder {
  font-size: 13px !important;
}
.rdt_TableFooter * {
  color: #000 !important;
}
.asrt-page-length .input-group-addon {
  background: none !important;
}
.table_filter input.form-control {
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 13px !important;
  height: 40px !important;
  box-shadow: 0px 0px 4px -1px #a3a3a3;
  background: white !important;
  font-weight: 600;
  color: #000000;
}
.manage-account .page-item .page-link {
  border: 0 !important;
  cursor: pointer;
  margin-left: 0;
}
.manage-account .page-item .page-link:hover {
  background: #2399f1;
  color: #fff;
}
.manage-account .asrt-pagination {
  box-shadow: 0px 0px 4px -1px #a3a3a3;
}
.manage-account .input-group.asrt-page-length select {
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 13px !important;
  height: 40px !important;
  box-shadow: 0px 0px 4px -1px #a3a3a3;
  background: white !important;
}
.manage-account .input-group-text {
  color: black;
  font-weight: 500;
}
.dashboard-inner-body {
  position: relative;
}
.api-permission-head {
  z-index: 999999999999;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
  cursor: pointer;
  padding: 10px 20px;
}
.api-permission-body .dashboard-inner-table {
  display: none;
  position: relative;
}
.api-permission-body .dashboard-inner-table:before {
  content: "\A";
  border-style: solid;
  border-width: 6px 9px 6px 0;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  left: 30px;
  transform: rotate(90deg);
  top: -10px;
}
.api-permission-body .dashboard-inner-table.show {
  display: block;
}
.api-permission-head h4 {
  font-size: 19px;
  clear: both;
}
.api-permission-head h4 i {
  float: right;
  background: #2399f1;
  color: white;
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 100%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.api-permission-head + div {
  background: #ffffff;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}
.dashboard-body .api-permission-body {
  margin-bottom: 20px;
}
.dashboard-body .api-permission-body:last-child {
  margin-bottom: 0px;
}
.hasCalendar {
  position: relative;
}
/*.hasCalendar:after{*/
/*position:absolute;*/
/*top:50%;*/
/*-webkit-transform: translateY(-50%);*/
/*-moz-transform: translateY(-50%);*/
/*-ms-transform: translateY(-50%);*/
/*-o-transform: translateY(-50%);*/
/*transform: translateY(-50%);*/
/*right:15px;*/
/*content: '\f073';*/
/*font-family:FontAwesome;*/
/*color: #2399f1;*/
/*}*/
.react-date-picker__wrapper {
}

.react-date-picker {
  height: 41px;
  width: 100%;
}

.react-date-picker .react-date-picker__inputGroup {
  padding-left: 10px;
}
.react-date-picker__inputGroup__input:focus {
  outline: 0;
  box-shadow: none;
  border: 0;
}
.calendar-flex {
  display: flex;
  flex: 2;
}

.calendar-flex .react-datepicker-wrapper {
  display: block !important;
  flex: 1;
}

.calendar-flex .react-datepicker-wrapper input[type="text"] {
  width: 100%;
  height: 50px;
  border: 0;
  box-shadow: 0px 0px 4px -1px #a3a3a3;
  padding: 0 17px;
}
ul.list-unstyled.childul.sdkUl {
}

ul.list-unstyled.childul.sdkUl a {
  position: relative;
  padding-left: 50px;
}

ul.list-unstyled.childul.sdkUl a i {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 20px;
  color: #2399f1;
}
.sdk-actions a,
.sdk-actions button {
  margin: 0 10px 0 0px;
  background: -webkit-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  color: white;
  display: inline-block;
  padding: 3px 20px;
  font-size: 15px;
  border: 0;
  border-radius: 40px;
}
.sdk-actions a i,
.sdk-actions button i {
  margin-right: 4px;
}
.dashboard-inner-body {
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(3, 27, 78, 0.04);
}
.formBox .css-yk16xz-control {
  min-height: 50px !important;
  background: white !important;
  -webkit-box-shadow: 0px 0px 10px 1px #a9a9a95e;
  -moz-box-shadow: 0px 0px 10px 1px #a9a9a95e;
  box-shadow: 0px 0px 10px 1px #a9a9a95e;
}
.formBox .css-1uccc91-singleValue {
  font-size: 14px;
}
.chartBox {
  position: relative;
}
.statsBoxLoader,
.chartBoxLoader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.6s linear;
  -moz-transition: all 0.6s linear;
  -ms-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
  z-index: 999999;
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #17d7b1;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
.statsBox {
  position: relative;
}
.statsBoxLoader.hide,
.chartBoxLoader.hide {
  opacity: 0;
  visibility: hidden;
}
.dashboard-header h1 i {
  color: #2399f1;
  position: relative;
  left: 10px;
  top: 2px;
}
.dashboard-inner-loader {
  position: fixed;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  margin-left: 330px;
  width: calc(100% - 330px);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  top: 0;
  -webkit-transition: all 0.6s linear;
  -moz-transition: all 0.6s linear;
  -ms-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.sidebar.sidebar-toggled + .dashboard-body .dashboard-inner-loader {
  margin-left: 60px;
  width: calc(100% - 60px);
}
.dashboard-inner-loader.hide {
  opacity: 0;
  visibility: hidden;
}
.statsBoxFooter,
.profileSec .dashboard-inner-table .table tfoot {
  display: none;
}
.dropdown-parent {
  display: flex;
  align-items: center;
  padding: 0px 30px 0px 30px;
  font-size: 15px;
  color: #000000;
  font-weight: 500;
  text-transform: capitalize;
  min-height: 55px;
}

.dropdown-parent i {
  margin-left: 10px;
}
.account-status .dropdown > button span b {
  background: #2399f1;
  color: white;
  padding: 3px 8px;
  border-radius: 40px;
  font-size: 13px;
}
.dashboard-form-body .form-control.error {
  box-shadow: 0px 0px 4px -1px #f44336;
  border: 1px solid #f44336;
}
.expired-icon {
  min-height: 284px;
}
.dashboard-form-body .form-control:disabled {
  background-color: #e9ecef !important;
  border: 1px solid transparent;
}
.profile-boxes .statsBoxBody h4 {
  text-transform: capitalize;
  line-height: 1.4;
  display: table;
  margin: 0 auto;
  font-size: 15px;
  padding: 5px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.profile-boxes .statsBoxBody {
  padding: 20px;
}
.dashboard-stats {
}

.profile-boxes.mb-4 .row {
  display: flex;
}

.profile-boxes.mb-4 .row > div {
  /* display: flex; */
}

.profile-boxes.mb-4 .row > div .statsBox {
  height: 100%;
}
.manage-account .input-group.asrt-page-length select > option:last-child {
  display: none;
}
.vpnAccountPro .row {
  margin-bottom: 30px;
}
.pbHead li.active-tab.nav-item {
  display: none;
}
.pbHead li.active-tab.nav-item.active {
  display: block;
}
.dashboard-inner-table .table td span:hover {
  background: #2399f1b5;
}
.dashboard-inner-table .blue-label {
  background: #2399f1 !important;
  text-transform: capitalize;
}
.res-table.manage-account.credit-history table.table tbody tr td:first-child {
  width: 40px;
}
.swal2-container.swal2-backdrop-show {
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999999999999999999999;
}
body.swal2-shown.swal2-height-auto {
  padding-right: 0 !important;
}
.swal2-container .swal2-styled.swal2-confirm {
  /* border: 0; */
  /* border-radius: .25em; */
  /* background: initial; */
  /* background-color: #3085d6; */
  /* color: #fff; */
  /* font-size: 1.0625em; */
  border-style: solid;
  border-width: 1px;
  border-color: rgb(29, 121, 191);
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 100%
  );
  color: white;
  padding: 5px 60px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 50px !important;
  text-transform: uppercase;
}
.swal2-styled.swal2-confirm:hover,
.swal2-styled.swal2-confirm:focus {
  background-image: linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 45%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(33, 137, 217) 0%,
    rgb(35, 153, 241) 45%
  );
}
.swal2-container .swal2-title {
  font-size: 20px;
  font-weight: 500;
}
.res-table.manage-account thead tr th.text-:nth-child(3) {
  width: 244px;
}
.onlineNowHead {
  background: #fff;
  position: relative;
}
.onlineNowHead ul {
  margin-bottom: 0;
  clear: both;
}
.onlineNowHead button {
  background: none;
  border: 0;
  display: block;
  padding: 0;
  color: #2399f1;
  font-weight: 600;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.onlineNowHead h3 {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  text-align: left;
  box-shadow: none;
  padding-left: 20px;
}
.error-illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px;
}
.ei-text {
  text-align: center;
  color: #2399f1;
  margin-top: 50px;
}
.ei-text p {
  margin-bottom: 0;
  margin-top: 7px;
  color: #a3a3a3;
}
.ei-parent {
  text-align: center;
}
.ei-parent > img {
  min-height: 220px;
}
.dashboard-inner-loader > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dashboard-inner-loader > div .sk-chase {
  margin-bottom: 30px;
}

.dashboard-inner-loader div h4 {
  color: #a3a3a3;
  font-size: 16px;
  text-transform: capitalize;
}
.ei-text p button {
  background: none;
  border: 0;
  padding: 4px 30px;
  margin-left: 7px;
  color: #fff;
  background: #2399f1;
  display: inline-block;
  font-size: 15px;
  border-radius: 50px;
}
.accountTypeParent {
  height: 50px;
  display: flex;
  align-items: center;
}
.leftlabel {
  color: #2399f1;
  font-weight: bold;
}
.labelParent {
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
*:required:focus {
  border: 1px solid #2399f1;
  outline: none;
}
body.swal2-height-auto {
  height: inherit !important;
}
.ei-parent.chart-data > img.img-fluid {
  max-height: inherit !important;
  min-height: inherit !important;
}
.chart-illustration {
  height: 300px;
}
.dashboard-inner-loader.ml-0.show {
  width: 100%;
  background: #ffffffd6;
}
.formBox.mb-4.resellersBox {
  position: relative;
}

.formBox.mb-4.resellersBox .xs-loader {
  position: absolute;
  top: 0;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formBox.mb-4.resellersBox .xs-loader .sk-chase {
  height: 20px;
  width: 20px;
}
.sk-chase-caption span {
  color: #a3a3a3;
  font-size: 12px;
  padding-left: 10px;
  font-weight: 500;
}
nav.navbar.navbar-light.bg-faded {
  padding: 0;
  display: none;
}
.navbar-light .navbar-toggler {
  background: white;
  border-radius: 0;
}
.navbarHead {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  width: 100%;
  background: #001f79;
}
nav.navbar ul.navbar-nav {
  padding: 20px 20px;
}
li.dropdown > a.nav-link,
li.nav-item > button {
  font-size: 15px;
  color: #a3a7b4;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  border: 0;
}
li.nav-item > button {
  padding: 0.5rem 0rem;
}
li.dropdown > a.nav-link.active {
  color: #212121 !important;
}
.dropdown-menu {
  /* box-shadow: 0px 0px 3px 0px rgba(3, 27, 78, 0.24); */
  border: 0;
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 10px 0;
}
li.dropdown > a.nav-link i {
  float: right;
  clear: both;
  font-weight: bold;
}
.dropdown-menu li a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 15px;
  color: #333333;
  font-weight: 500;
  text-transform: capitalize;
}
.dropdown-menu li a.active {
  font-weight: bolder;
  background: #e9f5fe;
  color: #212121;
}
.nav-bottom-wrap {
  display: flex;
  justify-content: space-between;
}
.Select.is-focused {
  box-shadow: 0px 0px 10px #ffeb3b;
}
.main-header {
  margin-left: 360px;
  width: calc(100% - 360px);
  /* background-image: -ms-linear-gradient( 0deg, rgb(1,122,172) 0%, rgb(23,215,177) 100%); */
  background-image: -moz-linear-gradient(
    0deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    #735AFF,
    #4D3AB5
  );
  
  transition: all 0.5s ease-in-out;
}
.sidebar.sidebar-toggled + .main-header {
  margin-left: 80px;
  width: calc(100% - 80px);
}
.main-header .header-logo img {
  max-width: 180px;
}
.main-header .user-dd {
  text-align: right;
}
.main-header .user-dd button.btn,
.main-header .user-dd button.btn:active,
.main-header .user-dd button.btn:focus,
.main-header .user-dd button.btn:focus-visible {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.main-header .user-dd span.username-toggle i.fa {
  line-height: 1;
  position: relative;
  top: -4px;
  margin-left: 10px;
  font-size: 14px;
}
.main-header .balance-tag {
  align-self: center;
  background-color: rgb(255 255 255 / 30%);
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}
.main-header .notification-icon {
  margin-right: 25px;
}
.main-header .notification-icon .notification-bell {
  position: relative;
}
.main-header .notification-icon .notification-bell .fa-bell {
  font-size: 20px;
  line-height: 20px;
  vertical-align: -webkit-baseline-middle;
  color: #fff;
  cursor: pointer;
}
.main-header .notification-icon .notification-bell span.notification-count {
  position: absolute;
  background-color: #dd5a5f;
  font-size: 10px;
  line-height: 1;
  color: #fff;
  border-radius: 50%;
  padding: 3px;
  padding-top: 4px;
  top: 0;
  right: -10px;
  width: 18px;
  height: 18px;
  text-align: center;
}
.dashboard-banner {
  background-color: #fff;
  padding: 40px 60px;
  font-family: "SofiaProLight";
  color: #000;
  box-shadow: 0 0 0.5rem rgb(0 0 0 / 15%);
}
.dashboard-banner h1 {
  line-height: 1;
  margin-bottom: 25px;
}
.dashboard-banner p {
  margin-bottom: 25px;
}
.dashboard-banner ul {
  list-style: none;
  padding-left: 30px;
}
.dashboard-banner ul li {
  position: relative;
  padding: 3px 0;
}
.dashboard-banner ul li a {
  text-decoration: underline;
}
.dashboard-banner ul li::before {
  content: "\f061";
  font-family: "FontAwesome";
  position: absolute;
  left: -30px;
  font-size: 12px;
  line-height: 1;
  top: 10px;
  color: #26ff26;
}
.api-docs-bar {
  background-image: -ms-linear-gradient(
    180deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -moz-linear-gradient(
    180deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -webkit-linear-gradient(
    -90deg,
    #735AFF,
    #725AFE
  );
  border-radius: 10px;
  padding: 20px;
  margin-top: 35px;
}
.api-docs-bar .txt-content {
  padding-left: 60px;
  color: #fff;
  position: relative;
  display: inline-block;
}
.api-docs-bar .txt-content .icon {
  position: absolute;
  left: 5px;
  top: 9px;
}
.api-docs-bar .txt-content .icon .fa {
  font-size: 34px;
}
.api-docs-bar .txt-content span {
  font-family: "SofiaProLight";
}
.w-button {
  color: #fff;
  font-family: "SofiaProLight";
  background-color: #28C675;
  padding: 8px 25px;
  border-radius: 10px;
  display: inline-block;
}
.api-docs-bar .w-cta {
  position: relative;
  top: 6px;
}
.component-heading {
  font-family: "SofiaProLight";
  position: relative;
  margin-bottom: 30px;
}
.component-heading::after {
  content: "";
  width: 30px;
  height: 2px;
  background-color: #0179ac;
  position: absolute;
  left: 0;
  bottom: -10px;
}
.component-heading {
  font-family: "SofiaProLight";
  position: relative;
  margin-bottom: 30px;
}
.heading-slogan {
  font-family: "SofiaProLight";
}
.component-heading::after {
  content: "";
  width: 30px;
  height: 2px;
  background-color: #0179ac;
  position: absolute;
  left: 0;
  bottom: -10px;
}
.component-heading.no-border::after {
  display: none;
}
.component-stats {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  margin-bottom: 35px;
}
.component-stats .box-head h5 {
  font-family: "SofiaProLight";
  padding: 15px;
  border-bottom: 1px solid #ccc;
}
.component-stats .box-content {
  padding: 20px;
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.component-stats .box-content .value {
  font-size: 52px;
  font-family: "SofiaProLight";
  line-height: 1;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 20px;
}
.component-stats .box-content .value::after {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #0179ac;
  position: absolute;
  bottom: 0;
  left: calc(50% - 10px);
}
.w-box {
  background-color: #fff;
  padding: 40px;
}
.w-box.minHeight {
  min-height: calc(100vh - 150px);
  padding: 12px;
}
.wallet-stat.component-stats .value::after {
  display: none;
}
.wallet-stat.component-stats .value {
  padding-bottom: 0;
}
.wallet-stat.component-stats .box-content > span {
  font-family: "SofiaProLight";
  font-size: 14px;
  margin-bottom: 8px;
}
.wallet-stat.component-stats .box-head h5 {
  border: 0;
  font-size: 30px;
  padding-bottom: 0;
  padding-top: 20px;
}
.wallet-stat.component-stats .box-content {
  padding-top: 0;
}
.form-check {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  padding-left: 35px;
}
.form-group > label {
  display: block;
}

.form-group .form-check:first-of-type {
  padding: 0;
}
.form-check label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  line-height: 1;
}
.form-check input[type="radio"] {
  vertical-align: middle;
  margin-right: 12px;
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: #ddf0ef;
  border-radius: 50%;
  transition: none !important;
}
.form-check input[type="radio"]:checked {
  background-color: #13c6b0;
  background-image: -webkit-linear-gradient(
    0deg,
    #28C675,
    #28C675
  );
  box-shadow: 0 0 5px #13c6b0;
  transition: none;
}
.form-control {
  outline: none !important;
  box-shadow: none !important;
  font-family: "SofiaProLight";
  padding: 10px 20px;
  height: auto;
}
label {
  font-family: "SofiaProLight";
}
.form-group > label {
  font-size: 14px;
}
.form-group {
  margin-bottom: 1.5rem;
}
.theme-gradient-btn {
  background-image: -ms-linear-gradient(
    0deg,
    #28C675,
    #28C675
  );
  background-image: -moz-linear-gradient(
    180deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    #28C675,
    #28C675
  );
  border: 0;
  outline: none;
  color: #fff;
  font-family: "SofiaProLight";
  font-size: 16px;
  padding: 8px 28px;
  border-radius: 6px;
  transition: opacity 0.5s linear !important;
  position: relative;
  z-index: 1;
  min-width: 180px;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.theme-gradient-btn::before {
  content: "";
  background-image: -ms-linear-gradient(
    0deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -moz-linear-gradient(
    0deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    #735AFF,
    #4D3AB5
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s linear !important;
}
.theme-gradient-btn:hover::before {
  opacity: 1;
}
.theme-gradient-btn:disabled {
  cursor: default;
  background-image: -webkit-linear-gradient(180deg, #e0e0e0 0%, #787878 100%);
}
.theme-gradient-btn:disabled:hover::before {
  display: none;
}
.theme-gradient-btn.mw-auto {
  min-width: auto;
}
details {
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 20px;
}
summary {
  position: relative;
  padding: 15px 20px;
  font-family: "SofiaProLight";
}
summary::marker {
  display: none !important;
  opacity: 0;
  content: "";
}
summary::after {
  content: "\f078";
  font-family: "FontAwesome";
  position: absolute;
  right: 20px;
  float: right;
  text-align: center !important;
  text-indent: 100px !important;
}
details[open] summary::after {
  content: "\f077";
}
details .content {
  padding: 15px 20px;
  display: block;
  background-color: #fcfcfc;
  font-family: "SofiaProLight";
}
details .content ul {
  list-style: none;
  position: relative;
}
details .content ul li {
  position: relative;
}
details .content ul li::before {
  content: "\f061";
  font-family: "FontAwesome";
  position: absolute;
  left: -30px;
  font-size: 12px;
  line-height: 1;
  top: 5.5px;
  color: #26ff26;
}
.tab-triggers .trigger-action svg {
  max-width: 70px;
}
.tab-triggers {
  display: flex;
  column-gap: 55px;
}
.tab-triggers .trigger-action {
  background-color: #fefefe;
  border: 1px solid #eee;
  flex: 1;
  text-align: center;
  padding: 50px 0;
  border-radius: 6px;
  cursor: pointer;
}
.tab-triggers .trigger-action .t-icon {
  margin-bottom: 15px;
}
.tab-triggers .trigger-action .t-title {
  font-size: 20px;
}
.tab-triggers .trigger-action.selected {
  background-image: -moz-linear-gradient(
    -132deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    #735AFF,
    #4D3AB5
  );
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.tab-triggers .trigger-action.selected .t-title {
  color: #fff;
}
.tab-triggers .trigger-action.selected .t-icon svg g {
  fill: #fff !important;
}
.custom_search_field {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
}
.custom_search_field input,
.custom_search_field select {
  border: 0;
  padding: 10px 20px;
}
.custom_search_field input {
  width: 67%;
}
.custom_search_field select {
  width: 32%;
  background-color: #f8f8f8;
  margin: 3px 2px 3px 3px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
input[type="text"],
select {
  font-family: "SofiaProLight";
}
input[type="text"]:focus-visible,
select:focus {
  outline: 0;
}
.custom_div_table {
  display: table;
  width: 100%;
  border: 1px solid #d4d4d4;
  font-family: "SofiaProLight";
}
.c_table_head {
  display: table-header-group;
}
.c_table_body {
  display: table-row-group;
  border: 1px solid #d4d4d4;
}
.c_row {
  display: table-row;
}
.c_table_body .c_row:last-child .c_data {
  border-bottom: 0;
}
.c_table_body .c_row:nth-child(odd) {
  background-color: #f2f2f2;
}
.c_data {
  display: table-cell;
  padding: 6px 20px;
  border-right: 1px solid #D4D4D4;
  border-bottom: 1px solid #D4D4D4;
  text-transform: capitalize;
  font-size: 14px;
}
.c_data:last-child {
  border-right: 0;
}
.c_table_head .c_row .c_data {
  padding: 15px 20px;
  text-transform: uppercase;
  color: #b6b6b6;
}
.c_data .ct_btn,
.dataTables_wrapper td .ct_btn {
  outline: 0;
  border: 0;
  background-color: #28aecb;
  color: #fff;
  padding: 4px;
  min-width: 28.8px;
  /* width: 100%; */
  border-radius: 6px;
  font-size: 14px;
}
.c_data .ct_btn:not(:first-child) {
  margin-left: 10px;
}
.c_data button.ct_btn:disabled {
  background-color: #ccc;
}
.c_data button.ct_btn:disabled:hover {
  opacity: 1;
}
.c_data .ct_btn:hover {
  opacity: 0.8;
}
.custom_div_table.have-error {
  border: 0;
  text-align: center;
  padding: 40px 0;
}
.custom_div_table.have-error .no-data {
  font-size: 26px;
}
.custom_div_table.have-error .no-data i.fa {
  color: var(--red);
  font-size: 26px;
  padding-right: 5px;
}
.site-loader {
  position: relative;
  width: 68px;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 5px;
  align-items: center;
  margin: 0 auto;
  min-height: 200px;
}
.site-loader div {
  left: 8px;
  width: 8px;
  background: #07c7ac;
  animation: site-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  transition: none;
}
.site-loader div:nth-child(1) {
  animation-delay: -0.6s;
  opacity: 0.2;
}
.site-loader div:nth-child(2) {
  animation-delay: -0.48s;
  opacity: 0.4;
}
.site-loader div:nth-child(3) {
  animation-delay: -0.36s;
  opacity: 0.6;
}
.site-loader div:nth-child(4) {
  animation-delay: -0.24s;
  opacity: 0.8;
}
.site-loader div:nth-child(5) {
  animation-delay: -0.12s;
}
@keyframes site-loader {
  0% {
    height: 62px;
  }
  50% {
    height: 56px;
  }
  80% {
    height: 38px;
  }
  90% {
    height: 34px;
  }
  95% {
    height: 32px;
  }
  100% {
    height: 32px;
  }
}
.d-flex.form-group > * {
  max-width: 50%;
  flex: 1;
}
.d-flex.form-group > select.form-control {
  max-width: 80px;
}
.d-flex.form-group > label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 70%;
  text-align: right;
  padding-right: 20px;
  margin-bottom: 0;
  line-height: 1;
}
.Tooltip-component {
  position: relative;
  display: inline-block;
}
.Tooltip-component .tt-content {
  position: absolute;
  border-width: 0.2px;
  border-color: rgb(183, 183, 183);
  border-style: solid;
  background-color: rgb(245, 245, 245);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 15px 20px;
  bottom: 0;
  /* max-width: 250px; */
  width: max-content;
  left: 30px;
  font-size: 14px;
  cursor: default;
  line-height: 1.3;
  font-family: "SofiaProLight";
}
.Tooltip-component .tt-icon .fa {
  vertical-align: middle;
  margin-left: 10px;
  font-size: 16px;
  color: #dedede;
  cursor: pointer;
}
.Tooltip-component .tt-icon:hover .fa,
.Tooltip-component .tt-icon.open .fa {
  color: #c0c0c0;
}
.Tooltip-component .tt-content p {
  margin-bottom: 0;
  margin-top: 10px;
}
.pagination-container {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.pagination-container .pagination a {
  border: 0;
  background-color: transparent;
  border: 1px solid #28aecb;
  margin-left: -1px;
  color: #28aecb;
  margin: 0 3px;
  line-height: 1;
  padding: 5px 8px;
  min-width: 28px;
  border-radius: 3px;
  display: block;
  text-align: center;
}
.pagination-container .pagination li:first-child a {
  margin-left: 0;
}
.pagination-container .pagination li.active a {
  color: #fff;
  background-color: #28aecb;
}
.pagination-container .pagination li.disabled a {
  color: #a7aaad !important;
  border-color: #dcdcde !important;
  background: #f6f7f7 !important;
  box-shadow: none !important;
  cursor: default;
  transform: none !important;
}
.pagination-container .pagination li a span {
  display: block;
  position: relative;
  top: -2px;
}
.stripe-input-styles {
  border: 1px solid #ccc;
  max-width: 600px;
  padding: 10px;
  border-radius: 4px;
}
.payment-form-price {
  padding: 12px;
  border: 1px solid #ccc;
}
.payment-method-btns {
  display: flex;
}
.payment-method-btns > button.active {
  background-color: #f3f3f3;
}
.payment-method-btns > * {
  flex: 1;
  border: 0;
  text-transform: capitalize;
  padding: 12px 26px 12px 56px;
  background-color: #fbfbfb;
  text-align: left;
  position: relative;
}
.payment-method-btns > button img {
  max-width: 150px;
  max-height: 22px;
  float: right;
  filter: grayscale(1);
}
.payment-method-btns > button.active img {
  filter: grayscale(0);
}
.payment-method-btns > button .dot {
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 12px;
  background-color: #ddf0ef;
  border-radius: 50%;
  box-shadow: inset 0 0 4px 1px rgb(0 0 0 / 10%);
}
.payment-method-btns > button.active .dot::after {
  content: "";
  background-color: #10bcaf;
  box-shadow: inset 0 0 5px 1px rgb(0 0 0 / 20%);
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  top: 20%;
  left: 20%;
}
.component-add_funds input#amount ~ .currency_symbol {
  position: absolute;
  top: 29px;
  padding: 13px 16px;
  display: none;
}
.component-add_funds input#amount:valid {
  padding-left: 32px !important;
}
.component-add_funds input#amount:valid ~ span.currency_symbol {
  display: block;
}
.grecaptcha-badge {
  display: none !important;
}
#records_per_page {
  max-width: 50px;
  padding: 0;
  display: inline;
  margin: 0 5px;
  font-size: 14px;
}

.tab-welcometriggers .trigger-welcomeaction svg {
  max-width: 45%;
  height: 90px;
}
.tab-welcometriggers {
  display: flex;
  column-gap: 20px;
}
.tab-welcometriggers .trigger-welcomeaction {
  background-color: #fefefe;
  border: 1px solid #eee;
  flex: 1;
  text-align: center;
  padding: 20px 0;
  border-radius: 6px;
  cursor: pointer;
}
.tab-welcometriggers .trigger-welcomeaction .t-welcomeicon {
  margin-bottom: 15px;
}
.tab-welcometriggers .trigger-welcomeaction .t-welcometitle {
  font-size: 20px;
}
.tab-welcometriggers .trigger-welcomeaction:hover {
  background-image: -moz-linear-gradient(
    -132deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  background-image: -webkit-linear-gradient(
    -132deg,
    rgb(1, 122, 172) 0%,
    rgb(23, 215, 177) 100%
  );
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.tab-welcometriggers .trigger-welcomeaction:hover .t-welcometitle {
  color: #fff;
}
.tab-welcometriggers .trigger-welcomeaction:hover p {
  color: #fff;
}
.trigger-welcomeaction:hover .t-welcomeicon path {
  fill: #fff !important;
}
.trigger-welcomeaction p {
  margin-top: 10px;
}

.Di-hearder {
  display: flex;
  justify-content: space-between;
  font-family: "SofiaProLight";
}
.status-div {
  text-align: center;
}
.status-div p {
  margin-bottom: 8px !important;
}
.status-div h3 {
  background-color: #fbfbfb;
  padding: 8px;
  border-radius: 9px;
  color: #36c55b;
}
.dpaid-p {
  margin-top: 8px;
}
.status-unpaid {
  color: #f44647 !important;
  padding: 8px 17px 8px 17px !important;
  margin: 8px 0px 8px 0px !important;
}
.status-div a {
  text-decoration: underline;
  color: #575757;
}
.Divider {
  border: 1px solid #eff3f6;
  width: 100%;
  background-color: #eff3f6;
  margin: 25px 0px 25px 0px;
}
.i-detail1 {
  text-align: end;
}
.des-head {
  display: flex;
  justify-content: space-between;
  color: #a6aaaf;
  margin-top: 45px;
}
.des-inner {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}
.des-inner1 {
  background-color: #f3f3f3;
  width: 65%;
  padding: 10px;
  border-radius: 4px 0px 0px 4px;
}
.des-inner2 {
  background-color: #fbfbfb;
  width: 35%;
  padding: 10px;
  border-radius: 0px 4px 4px 0px;
  text-align: end;
}
.table-transaction {
  margin-top: 40px;
  font-family: "SofiaProLight";
}
.invoice-btn {
  text-align: end;
  padding: 15px;
}
.i-printbtn {
  margin: 20px;
}
.d-invoicehead {
  text-align: center;
}

.d-invoicehead::after {
  background-color: transparent !important;
}
.head-line {
  position: relative;
}
.head-line::after {
  content: "";
  width: 30px;
  height: 2px;
  background-color: #0179ac;
  position: absolute;
  left: 85px;
  bottom: -10px;
}
.dashboard-invoice {
  margin: 50px 160px;
}
.search-field input {
  padding: 4px 8px;
  font-size: 14px;
}
.search-field input[type="date"]:focus-visible {
  outline: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  width: auto;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.dataTables_wrapper table {
  border: 1px solid #d4d4d4;
}
.dataTables_wrapper th,
.dataTables_wrapper td {
  border-right: 1px solid #d4d4d4 !important;
  border-bottom: 1px solid #d4d4d4 !important;
}
.dataTables_wrapper table thead th {
  padding: 8px 10px;
  text-transform: uppercase;
  color: #b6b6b6;
  font-weight: 100 !important;
  font-family: "SofiaProLight";
  border-right: 1px solid #d4d4d4 !important;
}
.dataTables_wrapper td {
  display: table-cell;
  padding: 5px 10px;
  border-right: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  text-transform: capitalize;
  font-weight: 100;
  font-family: "SofiaProLight";
  color: #575757;
}
div.dataTables_wrapper div.mdb-datatable-entries {
  padding-left: 0;
}
.mdb-dataTables_paginate .fas {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div.dataTables_wrapper .flex-row-reverse .mdb-datatable-entries {
  padding-left: 0;
}
.dataTables_wrapper .dataTables_paginate .pagination {
  justify-content: end;
}
.dataTables_wrapper
  .dataTables_paginate
  .pagination
  .page-item:not(.disabled)
  .page-link {
  color: #2399f1;
  cursor: pointer;
}
.dataTables_wrapper
  .dataTables_paginate
  .pagination
  .page-item.active
  .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2399f1;
  border-color: #2399f1;
}
.dataTables_wrapper .dataTables_paginate .dataTables_info {
  height: 100%;
  display: flex;
  align-items: center;
}

/* iPad */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .dashboard-banner {
    padding: 20px 20px;
  }
  .dashboard-banner .w-box {
    padding: 0;
  }
  .api-docs-bar .txt-content {
    max-width: 520px;
  }
  .api-docs-bar .w-cta {
    top: unset;
    bottom: -30px;
  }
  .Tooltip-component .tt-content {
    display: none;
  }
  .Tooltip-component .tt-icon.open ~ .tt-content {
    display: block;
  }
  .tab-triggers {
    column-gap: 15px;
  }
  div[data-test="table"] {
    overflow-x: scroll;
    margin-bottom: 25px;
  }

  .dropdown button a {
    color: #a3a7b4;
  }
}

@media only screen and (min-width: 936px) and (max-width: 1024px) {
  div[data-test="table"] {
    overflow-x: scroll;
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1500px) {
  .pay-btn {
    display: flex;
  }
}
@media only screen and (min-width: 200px) and (max-width: 380px) {
  .form-check {
    padding-left: 0px;
  }
}
.notification-head-wrap {
  display: flex;
}

/*Mobile responsive*/
@media only screen and (min-width: 200px) and (max-width: 600px) {
  .accountBox {
    padding: 20px 20px;
  }
  div[data-test="table"] {
    overflow-x: scroll;
    margin-bottom: 25px;
  }
  .head-line-notify {
    display: block;
  }
  .wp-box {
    padding: 12px !important;
  }
  .custom_search_field {
    font-size: 12px;
  }
  .accountMain {
    height: calc(110vh - 3px);
  }
  .offset-3 {
    margin-left: 0 !important;
  }
  .sidebar {
    display: none;
  }
  .dashboard-body {
    width: 100%;
    margin-left: 0;
    padding: 30px 20px;
  }

  .dashboard-stats > .row > div,
  .dashboard-charts > .row > div,
  .table-foot.asrt-table-foot > div,
  .asrt-table-head > div,
  .accountStats > .row > div,
  .profile-boxes > .row > div {
    margin-bottom: 25px;
    width: 100%;
  }

  .dashboard-stats > .row > div:last-child,
  .dashboard-charts > .row > div:last-child,
  .asrt-table-head > div:last-child,
  .accountStats > .row > div:last-child,
  .profile-boxes > .row > div:last-child {
    margin-bottom: 0;
  }
  .error-illustration.chart-illustration {
    padding: 0px 20px;
    width: 100%;
  }
  .chartBox .white-space.pr-3 {
    padding-right: 0 !important;
  }
  .dashboard-charts .row div .chartBox {
    margin-bottom: 20px;
  }
  .chartBox {
    overflow: hidden;
  }
  .dashboard-stats .statsBoxBody h1,
  .statsBox h1 {
    font-size: 26px;
  }
  .dashboard-stats .statsBoxBody h4 {
    font-size: 18px;
  }
  .dashboard-header > div {
    width: 100%;
    text-align: center;
    float: none;
  }
  .dashboard-header > div > button,
  .dashboard-header > div > a {
    margin: auto;
  }
  span.input-group-text {
    font-size: 13px;
  }

  .dashboard-header h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .dashboard-modal-footer ul li {
    display: block;
    width: 100%;
    margin: 0 0 10px;
  }
  .dashboard-modal-footer ul {
    display: block;
  }
  .dashboard-modal-head h3 {
    font-size: 16px;
  }
  div#as-react-datatable-table-head {
    padding-top: 5px;
  }
  .dashboard-inner-table .table th,
  .rdt_TableHead .rdt_TableHeadRow > div {
    font-size: 10px;
  }
  .dashboard-inner-table .table td,
  .rdt_TableBody > div > div {
    font-size: 11px;
  }
  .asrt-table-head > div:last-child,
  .table-foot.asrt-table-foot > div {
    float: none !important;
  }
  ul.pagination.justify-content-end.asrt-pagination li {
    font-size: 12px;
  }
  ul.pagination.justify-content-end.asrt-pagination {
    text-align: center !important;
  }
  .table-foot.asrt-table-foot > div nav {
    float: none;
  }
  .dashboard-inner-loader {
    margin-left: 0;
    width: 100%;
  }
  .dashboard-header h4 {
    margin: 0 0 10px;
    font-size: 16px;
  }
  .dashboard-header h4 span {
    margin-top: 10px;
  }
  .ei-parent > img {
    min-height: inherit;
  }
  .error-illustration {
    padding: 20px;
  }
  .sdk-actions a,
  .sdk-actions button {
    padding: 5px 6px;
    font-size: 12px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .sdk-actions a i,
  .sdk-actions button i {
    display: none;
  }
  table#mobile-datatable thead th {
    min-width: 170px !important;
  }
  .vpnHeader h4 span,
  .vpnHeader h1 span {
    margin: 10px 0 10px 0;
    display: block;
  }
  .pbHead li {
    width: 100%;
  }
  .pbHead li a {
    width: 100%;
  }
  .form-group .row > div,
  .dashboard-header > div > ul li {
    margin-bottom: 15px;
  }
  .form-group .row > div:last-child,
  .dashboard-header > div > ul li:last-child {
    margin-bottom: 0px;
  }
  .dashboard-header > div > ul {
    padding-left: 0;
  }
  .sidebar.sidebar-toggled ~ .dashboard-body {
    width: 100%;
    margin-left: 0px;
    padding: 20px;
  }
  .sidebar.sidebar-toggled + .main-header {
    margin-left: 0px;
    width: 100%;
  }
  .header-logo {
    display: none;
  }

  .api-docs-bar .w-cta {
    width: 100%;
    text-align: center;
    margin-top: 12px;
  }
  .tab-welcometriggers {
    display: block;
  }
  .dashboard-banner {
    padding: 40px 25px;
  }
  .dashboard-banner h1 {
    line-height: 1.4;
    margin-bottom: 25px;
    font-size: 30px;
  }
  .w-box {
    padding: 0px;
  }
  .tab-welcometriggers .trigger-welcomeaction {
    margin-top: 12px;
  }
  .pull-right {
    float: inherit !important;
  }
  .user-dd {
    width: 115px;
  }
  .component-stats {
    min-height: 305px;
  }
  .edit-profile-details {
    padding: 20px;
  }
  .generate-coupons-form {
    padding: 20px;
  }
  .faq-box {
    padding: 10px;
  }
  .tab-triggers {
    display: block;
    padding: 10px;
  }
  .trigger-action {
    margin-top: 10px;
  }
  .tab-areas {
    padding: 10px;
  }
  .custom_search_field select {
    width: 60%;
  }
  .payment-method-btns {
    flex-wrap: wrap;
  }
  .fund-box {
    padding: 10px;
  }
  .secure_payment_slogan {
    text-align: start;
    margin-left: 0px;
  }
  .secure-box {
    text-align: center;
  }
  .mcafee-logo {
    padding: 15px;
    text-align: center;
  }
  .navbarHead {
    background-image: -webkit-linear-gradient(
      0deg,
      #735AFF,
      #4D3AB5
    );
  }
  .navbar-light .navbar-toggler {
    background: transparent;
  }
  .logo-img-nav {
    height: 17px !important;
  }
  .PM-img {
    display: none;
  }

  .payment-method-btns > * {
    display: block !important;
    width: 100%;
    flex: none;
    margin-bottom: 7px;
  }
  .custom_div_table {
    overflow-x: scroll;
    display: block;
  }
  .accountFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
    text-align: center;
  }
  .divider {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    margin: 10px 0px 10px 0px;
  }
  .main-header {
    display: none;
  }
  .mbl-profile-nav {
    font-size: 15px;
    color: #a3a7b4;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    background: none;
    border: 0;
  }
  .c_data .ct_btn {
    min-width: 22.8px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .main-header {
    display: none;
  }
  .mbl-profile-nav {
    font-size: 15px;
    color: #a3a7b4;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    background: none;
    border: 0;
  }
  .divider {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    margin: 10px 0px 10px 0px;
  }
  .custom_div_table {
    overflow-x: scroll;
    display: block;
  }
  .navbarHead {
    background-image: -webkit-linear-gradient(
      0deg,
      #735AFF,
      #4D3AB5
    );
  }
  .navbar-light .navbar-toggler {
    background: transparent;
  }
  .logo-img-nav {
    height: 17px !important;
  }
  .accountBox {
    padding: 20px 20px;
  }
  .accountMain {
    height: calc(110vh - 3px);
  }
  .offset-3 {
    margin-left: 0 !important;
  }
  .sidebar.sidebar-toggled ~ .dashboard-body {
    margin-left: 0px;
    width: 100%;
    padding: 20px;
  }
}

/*Small Tablets responsive*/
@media only screen and (min-width: 501px) and (max-width: 700px) {
  .offset-3 {
    margin-left: 0 !important;
  }
  .sidebar {
    display: none;
  }
  .dashboard-body {
    width: 100%;
    margin-left: 0;
    padding: 30px 20px;
  }

  .dashboard-stats > .row > div,
  .dashboard-charts > .row > div,
  .table-foot.asrt-table-foot > div,
  .asrt-table-head > div,
  .accountStats > .row > div,
  .profile-boxes > .row > div {
    margin-bottom: 25px;
    width: 100%;
  }

  .dashboard-stats > .row > div:last-child,
  .dashboard-charts > .row > div:last-child,
  .asrt-table-head > div:last-child,
  .accountStats > .row > div:last-child,
  .profile-boxes > .row > div:last-child {
    margin-bottom: 0;
  }
  .error-illustration.chart-illustration {
    padding: 0px 20px;
    width: 100%;
  }
  .chartBox .white-space.pr-3 {
    padding-right: 0 !important;
  }
  .dashboard-charts .row div .chartBox {
    margin-bottom: 20px;
  }
  .chartBox {
    overflow: hidden;
  }
  .dashboard-stats .statsBoxBody h1,
  .statsBox h1 {
    font-size: 26px;
  }
  .dashboard-stats .statsBoxBody h4 {
    font-size: 18px;
  }
  .dashboard-header > div {
  }
  .dashboard-header > div > button,
  .dashboard-header > div > a {
    margin: auto;
  }
  span.input-group-text {
    font-size: 13px;
  }

  .dashboard-header h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .dashboard-modal-footer ul li {
    display: block;
    width: 100%;
    margin: 0 0 10px;
  }
  .dashboard-modal-footer ul {
    display: block;
  }
  .dashboard-modal-head h3 {
    font-size: 16px;
  }
  div#as-react-datatable-table-head {
    padding-top: 5px;
  }
  .dashboard-inner-table .table th,
  .rdt_TableHead .rdt_TableHeadRow > div {
    font-size: 13px;
  }
  .dashboard-inner-table .table td,
  .rdt_TableBody > div > div {
    font-size: 14px;
  }
  .asrt-table-head > div:last-child,
  .table-foot.asrt-table-foot > div {
    float: none !important;
  }
  ul.pagination.justify-content-end.asrt-pagination li {
    font-size: 12px;
  }
  ul.pagination.justify-content-end.asrt-pagination {
    text-align: center !important;
  }
  .table-foot.asrt-table-foot > div nav {
    float: none;
  }
  .dashboard-inner-loader {
    margin-left: 0;
    width: 100%;
  }
  .dashboard-header h4 {
    margin: 0 0 10px;
    font-size: 16px;
  }
  .dashboard-header h4 span {
    margin-top: 10px;
  }
  .ei-parent > img {
    min-height: inherit;
  }
  .error-illustration {
    padding: 20px;
  }
  .sdk-actions a,
  .sdk-actions button {
    padding: 5px 6px;
    font-size: 12px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .sdk-actions a i,
  .sdk-actions button i {
    display: none;
  }
  table#mobile-datatable thead th {
    min-width: 170px !important;
  }
  .vpnHeader h4 span,
  .vpnHeader h1 span {
    margin: 10px 0 10px 10px;
  }
  .pbHead li {
    width: 100%;
  }
  .pbHead li a {
    width: 100%;
  }
  .form-group .row > div,
  .dashboard-header > div > ul li {
    margin-bottom: 15px;
  }
  .form-group .row > div:last-child,
  .dashboard-header > div > ul li:last-child {
    margin-bottom: 0px;
  }
  .dashboard-header > div > ul {
    padding-left: 0;
  }
  .table-foot.asrt-table-foot > div,
  .asrt-table-head > div {
    width: 50%;
    margin-bottom: 0;
  }
  .table-foot.asrt-table-foot > div {
    margin-bottom: 10px;
  }
  .pbHead li a {
    text-align: center;
    display: block;
  }
  .ei-text p button {
    display: block;
    margin: 10px auto 0;
  }
  .dashboard-header button,
  .dashboard-header a {
    padding: 3px 10px;
  }
}
/*Small Tablets responsive*/
@media only screen and (min-width: 701px) and (max-width: 999px) {
  .sidebar {
    display: none;
  }
  .dashboard-body {
    width: 100%;
    margin-left: 0;
    padding: 30px 20px;
  }

  .dashboard-stats > .row > div,
  .dashboard-charts > .row > div,
  .table-foot.asrt-table-foot > div,
  .asrt-table-head > div,
  .accountStats > .row > div,
  .profile-boxes > .row > div {
    margin-bottom: 25px;
    width: 100%;
  }

  .dashboard-stats > .row > div:last-child,
  .dashboard-charts > .row > div:last-child,
  .asrt-table-head > div:last-child,
  .accountStats > .row > div:last-child,
  .profile-boxes > .row > div:last-child {
    margin-bottom: 0;
  }
  .error-illustration.chart-illustration {
    padding: 0px 20px;
    width: 100%;
  }
  .chartBox .white-space.pr-3 {
    padding-right: 0 !important;
  }
  .dashboard-charts .row div .chartBox {
    margin-bottom: 20px;
  }
  .chartBox {
    overflow: hidden;
  }
  .dashboard-stats .statsBoxBody h1,
  .statsBox h1 {
    font-size: 26px;
  }
  .dashboard-stats .statsBoxBody h4 {
    font-size: 18px;
  }
  .dashboard-header > div {
  }
  .dashboard-header > div > button,
  .dashboard-header > div > a {
    margin: auto;
  }
  span.input-group-text {
    font-size: 13px;
  }

  .dashboard-header h1 {
    font-size: 20px;
  }
  .dashboard-modal-footer ul li {
    display: block;
    width: 100%;
    margin: 0 0 10px;
  }
  .dashboard-modal-footer ul {
    display: block;
  }
  .dashboard-modal-head h3 {
    font-size: 16px;
  }
  div#as-react-datatable-table-head {
    padding-top: 5px;
  }
  .dashboard-inner-table .table th,
  .rdt_TableHead .rdt_TableHeadRow > div {
    font-size: 13px;
  }
  .dashboard-inner-table .table td,
  .rdt_TableBody > div > div {
    font-size: 14px;
  }
  .asrt-table-head > div:last-child,
  .table-foot.asrt-table-foot > div {
    float: none !important;
  }
  ul.pagination.justify-content-end.asrt-pagination li {
    font-size: 12px;
  }
  ul.pagination.justify-content-end.asrt-pagination {
    text-align: center !important;
  }
  .table-foot.asrt-table-foot > div nav {
    float: none;
  }
  .dashboard-inner-loader {
    margin-left: 0;
    width: 100%;
  }
  .dashboard-header h4 {
    margin: 0 0 10px;
    font-size: 16px;
  }
  .dashboard-header h4 span {
    margin-top: 10px;
  }
  .ei-parent > img {
    min-height: inherit;
  }
  .error-illustration {
    padding: 20px;
  }
  .sdk-actions a,
  .sdk-actions button {
    padding: 5px 6px;
    font-size: 12px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .sdk-actions a i,
  .sdk-actions button i {
    display: none;
  }
  table#mobile-datatable thead th {
    min-width: 170px !important;
  }
  .vpnHeader h4 span,
  .vpnHeader h1 span {
    margin: 10px 0 10px 10px;
  }
  .pbHead li {
  }
  .pbHead li a {
  }
  .form-group .row > div,
  .dashboard-header > div > ul li {
    margin-bottom: 15px;
  }
  .form-group .row > div:last-child,
  .dashboard-header > div > ul li:last-child {
    margin-bottom: 0px;
  }
  .dashboard-header > div > ul {
    padding-left: 0;
  }
  .table-foot.asrt-table-foot > div,
  .asrt-table-head > div {
    width: 50%;
    margin-bottom: 0;
  }
  .table-foot.asrt-table-foot > div {
    margin-bottom: 10px;
  }
  .pbHead li a {
    text-align: center;
    display: block;
  }
  .ei-text p button {
    display: block;
    margin: 10px auto 0;
  }
  .dashboard-header button,
  .dashboard-header a {
    padding: 3px 10px;
  }
  body .col-md-3 {
    width: 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  div#as-react-datatable-table-body > div {
    overflow-x: scroll;
    margin-bottom: 20px;
  }
  .pbHead li a {
    padding: 20px 10px;
  }
  .pbHead {
    padding: 0px 10px;
  }
  .dashboard-stats > .row .statsBox {
    height: inherit;
  }
  .dashboard-charts > .row:first-child > div {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*Ipad pro screen size*/
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .sidebar {
    display: none;
  }
  .dashboard-body {
    width: 100%;
    margin-left: 0;
    padding: 30px 20px;
  }

  .dashboard-stats > .row > div,
  .dashboard-charts > .row > div,
  .table-foot.asrt-table-foot > div,
  .asrt-table-head > div,
  .accountStats > .row > div,
  .profile-boxes > .row > div {
    margin-bottom: 25px;
    width: 100%;
  }

  .dashboard-stats > .row > div:last-child,
  .dashboard-charts > .row > div:last-child,
  .asrt-table-head > div:last-child,
  .accountStats > .row > div:last-child,
  .profile-boxes > .row > div:last-child {
    margin-bottom: 0;
  }
  .error-illustration.chart-illustration {
    padding: 0px 20px;
    width: 100%;
  }
  .chartBox .white-space.pr-3 {
    padding-right: 0 !important;
  }
  .dashboard-charts .row div .chartBox {
    margin-bottom: 20px;
  }
  .chartBox {
    overflow: hidden;
  }
  .dashboard-stats .statsBoxBody h1,
  .statsBox h1 {
    font-size: 26px;
  }
  .dashboard-stats .statsBoxBody h4 {
    font-size: 18px;
  }
  .dashboard-header > div {
  }
  .dashboard-header > div > button,
  .dashboard-header > div > a {
    margin: auto;
  }
  span.input-group-text {
    font-size: 13px;
  }

  .dashboard-header h1 {
    font-size: 20px;
  }
  .dashboard-modal-footer ul li {
    display: block;
    width: 100%;
    margin: 0 0 10px;
  }
  .dashboard-modal-footer ul {
    display: block;
  }
  .dashboard-modal-head h3 {
    font-size: 16px;
  }
  div#as-react-datatable-table-head {
    padding-top: 5px;
  }
  .dashboard-inner-table .table th,
  .rdt_TableHead .rdt_TableHeadRow > div {
    font-size: 16px;
  }
  .dashboard-inner-table .table td,
  .rdt_TableBody > div > div {
    font-size: 17px;
  }
  .asrt-table-head > div:last-child,
  .table-foot.asrt-table-foot > div {
    float: none !important;
  }
  ul.pagination.justify-content-end.asrt-pagination li {
    font-size: 12px;
  }
  ul.pagination.justify-content-end.asrt-pagination {
    text-align: center !important;
  }
  .table-foot.asrt-table-foot > div nav {
    float: none;
  }
  .dashboard-inner-loader {
    margin-left: 0;
    width: 100%;
  }
  .dashboard-header h4 {
    margin: 0 0 10px;
    font-size: 16px;
  }
  .dashboard-header h4 span {
    margin-top: 10px;
  }
  .ei-parent > img {
    min-height: inherit;
  }
  .error-illustration {
    padding: 20px;
  }
  .sdk-actions a,
  .sdk-actions button {
    padding: 5px 6px;
    font-size: 16px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .sdk-actions a i,
  .sdk-actions button i {
    display: none;
  }
  table#mobile-datatable thead th {
    min-width: 230px !important;
  }
  .vpnHeader h4 span,
  .vpnHeader h1 span {
    margin: 10px 0 10px 10px;
  }
  .pbHead li {
  }
  .pbHead li a {
  }
  .form-group .row > div,
  .dashboard-header > div > ul li {
    margin-bottom: 15px;
  }
  .form-group .row > div:last-child,
  .dashboard-header > div > ul li:last-child {
    margin-bottom: 0px;
  }
  .dashboard-header > div > ul {
    padding-left: 0;
  }
  .table-foot.asrt-table-foot > div,
  .asrt-table-head > div {
    width: 50%;
    margin-bottom: 0;
  }
  .table-foot.asrt-table-foot > div {
    margin-bottom: 10px;
  }
  .pbHead li a {
    text-align: center;
    display: block;
  }
  .ei-text p button {
    display: block;
    margin: 10px auto 0;
  }
  .dashboard-header button,
  .dashboard-header a {
    padding: 3px 10px;
  }
  body .col-md-3 {
    width: 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  div#as-react-datatable-table-body > div {
    overflow-x: scroll;
    margin-bottom: 20px;
  }
  .pbHead li a {
    padding: 20px 10px;
  }
  .dashboard-stats > .row .statsBox {
    height: inherit;
  }
  .dashboard-charts > .row:first-child > div {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 200px) and (max-width: 1200px) {
  .Cbox {
    display: block;
  }
  .notification-head-wrap {
    display: block !important;
  }
  .notification-menu {
    display: flex !important;
    width: 100% !important;
    text-align: start;
  }
  .r-field {
    width: 100% !important;
  }
  .r-terms input {
    margin-right: 10px;
    margin-top: 8px;
  }
  .r-terms {
    display: flex;
  }
}
@media only screen and (min-width: 200px) and (max-width: 900px) {
  .filter-mblwrap {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .filter-mbl {
    display: flex;
    margin-bottom: 20px !important;
  }
  .Tooltip-component .tt-content {
    left: -134px !important;
    top: -50px !important;
  }
}
@media only screen and (max-width: 1201px) {
  nav.navbar.navbar-light.bg-faded {
    display: block;
  }
  .dashboard-invoice {
    margin: 0;
  }
  .table-transaction {
    display: block;
    overflow-x: scroll;
  }
}



/*  */



/* .theme-gradient-btn {
background: -webkit-linear-gradient(0deg,#28C675,#28C675);
} */
