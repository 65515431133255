@media print {
    .main-header,
    .sidebar,
    .action-btns {
        display: none !important;
    }
    .sidebar.sidebar-toggled ~ .dashboard-body,
    .dashboard-body {
        width: 900px;
        padding: 0;
        margin: 0 auto 0;
    }
    .dashboard-invoice {
        margin: 0;
        margin-top: 60px;
    }
}